import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import baseTheme from './theme.js';

const MyThemeProvider = ({ children }) => {
  const organization = useSelector((state) => state.organization);

  const [primaryColor, setPrimaryColor] = useState(baseTheme.palette.brand.brand);

  const theme = useMemo(
    () => createTheme({ ...baseTheme, palette: { ...baseTheme.palette, brand: { ...baseTheme.palette.brand, brand: primaryColor } } }),
    [primaryColor]
  );

  useEffect(() => {
    console.log(organization);
    setPrimaryColor(organization?.color || baseTheme.palette.brand.brand);
  }, [organization.color]);

  // customize variants

  theme.typography.h1 = {
    color: theme.palette.typography.heading.primary,
    fontSize: '1.2rem',
    fontWeight: 700,
    '@media (min-width:600px)': {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem'
    }
  };

  theme.typography.h2 = {
    color: theme.palette.typography.text.primary,
    fontSize: '1rem',
    fontWeight: 400,
    '@media (min-width:600px)': {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem'
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: theme.palette.surfaces.background }
        }}
      />
      {children}
    </ThemeProvider>
  );
};

export default MyThemeProvider;
