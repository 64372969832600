const axios = require('axios');
const { notify } = require('../utils/notification');

async function getContacts(apiKey) {
  try {
    //return [];
    var config = {
      method: 'get',
      url: 'https://delicate-mud-81d7.nanosolutions.workers.dev/?https://app.jobnimbus.com/api1/contacts',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `bearer ${apiKey}`
      }
    };

    const response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });

    return response;
  } catch (error) {
    return notify('danger', 'Error', `Could not load contacts: ${error.message}`);
  }
}

async function createFile(apiKey, blob, fileName, jnid) {
  try {
    var base64result = blob.split(',')[1];

    //var base64result = blob;
    var raw = JSON.stringify({
      data: base64result,
      is_private: false,
      related: [jnid],
      type: 7,
      subtype: 'contact',
      filename: fileName,
      description: 'Fence sketch',
      date: Date.now(),
      persist: true
    });

    var config = {
      method: 'post',
      url: 'https://delicate-mud-81d7.nanosolutions.workers.dev/?https://app.jobnimbus.com/api1/files',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        Authorization: `bearer ${apiKey}`
      },
      data: raw
    };

    const response = await axios(config)
      .then(function (response) {
        notify('success', 'Success', 'File successfully uploaded to JobNimbus');
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });

    return response;
  } catch (error) {
    return notify('danger', 'Error', `Failed to upload: ${error.message}`);
  }
}

export { getContacts, createFile };
