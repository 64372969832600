import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import './index.css';
import 'react-notifications-component/dist/theme.css';
import 'tw-elements';
import App from './App';
import { ToolProvider } from './contexts/ToolContext';

ReactDOM.render(
  <Provider store={store}>
    <ToolProvider>
      <App />
    </ToolProvider>
  </Provider>,
  document.getElementById('main')
);
