import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { useAuthContext } from '../../contexts/AuthContext';
import { query, collection, where, getDocs, doc, getDoc, addDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { login, acceptInviteSignInWithGoogle, auth } from '../../api/auth';

import { db } from '../../api/firebase';
import { createCheckoutSession, createPortalLink } from '../../api/stripe';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

export default function InviteLink() {
  let location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  //const [isValidInviteLink, setIsValidInviteLink] = useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const inviteToken = searchParams.get('invite');

    try {
      var decoded = jwt_decode(inviteToken);
      const isValid = isValidInviteLink(decoded.inviteId, decoded.exp);
      if (!isValid) throw Error('Invalid invite link');
      acceptInviteSignInWithGoogle(decoded.organizationId).then(async () => {
        const inviteRef = doc(db, 'invites', decoded.inviteId);
        let inviteData = (await getDoc(inviteRef)).data();
        await updateDoc(inviteRef, { ...inviteData, status: 'accepted' });
        window.location = '/';
      });
    } catch (error) {
      return console.log(error.message);
    }
    console.log(decoded);
  }, [location]);

  const isValidInviteLink = async (inviteId, expirationDate) => {
    if (expirationDate > Date.now()) {
      console.log('expired');
      return false;
    }
    const inviteRef = doc(db, 'invites', inviteId);
    let inviteData = (await getDoc(inviteRef)).data();
    if (inviteData) return true;
    return false;
  };
  return <div>InviteLink</div>;
}
