import { getFunctions, httpsCallable } from 'firebase/functions';

export default async function createPortalLink() {
  const functions = getFunctions();

  const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

  const { data } = await functionRef({
    returnUrl: window.location.origin + '/settings/billing',
    locale: 'auto' // Optional, defaults to "auto"
    //configuration: 'test_bIY5l9aSM7Sm27C144' // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
  });
  window.location.assign(data.url);
}
