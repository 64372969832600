import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Switch, useNavigate } from 'react-router-dom';
import { query, collection, where, getDocs, getDoc } from 'firebase/firestore';
import { auth, db, getStripeRole } from './api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import './index.css';
import Sketch from './pages/sketch/Sketch';
import History from './pages/history/History';
import Settings from './pages/settings/Settings';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Logout from './pages/auth/Logout';
import Header from './pages/sketch/components/KonvaToolsAppBar';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuthContext, setUser } from './contexts/AuthContext';
import { ReactNotifications } from 'react-notifications-component';

import { getUsersOrganization, saveUserJobberIntegrationSettings } from './api/user';
import JobNimbusSettings from './pages/settings/outlets/JobNimbusSettings';
import JobberSettings from './pages/settings/outlets/JobberSettings';
import Customization from './pages/settings/outlets/Customization';
import Team from './pages/settings/outlets/Team';
import Billing from './pages/settings/outlets/Billing';
import theme from './Theme/theme.js';
import ThemeProvider from './Theme/ThemeProvider.js';
import InviteLink from './pages/auth/InviteLink';
import Support from './pages/settings/outlets/Support';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './components/Loader.js';

const { refreshAccessToken } = require('./api/jobber');

export default function App() {
  const [user, loading, error] = useAuthState(auth);

  // useEffect(async () => {
  //   // if (!user || loading) return;
  //   // let userData = user.providerData[0];
  //   // const userRef = await getDocs(query(collection(db, 'users'), where('uid', '==', user.uid))).then((querySnapshot) => {
  //   //   console.log(querySnapshot);
  //   //   return querySnapshot.docs[0].ref;
  //   // });
  //   // userData = (await getDoc(userRef)).data();
  //   // console.log(userData);
  //   // let dataSource = Object.keys(userData).filter((e, index) => {
  //   //   if (e.includes('Integration') && userData[e].enabled) {
  //   //     return e;
  //   //   }
  //   // });
  //   // switch (dataSource[0]) {
  //   //   case 'jobberIntegration':
  //   //     let refresh_token = userData[dataSource].refresh_token;
  //   //     refreshAccessToken(refresh_token, process.env.REACT_APP_JOBBER_CLIENT_ID, process.env.REACT_APP_JOBBER_CLIENT_SECRET);
  //   //     break;
  //   //   default:
  //   //     break;
  //   // }
  //   // let stripeRole = await getStripeRole();
  //   // let organizationSettings = await getUsersOrganization(userRef);
  //   // dispatch(setUser({ ...userData, ref: userRef, stripeRole: stripeRole || null, organizationSettings: organizationSettings }));
  //   // if (userData.jobberIntegration?.enabled) {
  //   //   refreshAccessToken(userData.jobberIntegration.refresh_token, process.env.REACT_APP_JOBBER_CLIENT_ID, process.env.REACT_APP_JOBBER_CLIENT_SECRET).then(
  //   //     (data) =>
  //   //       saveUserJobberIntegrationSettings(userRef, {
  //   //         ...userData.jobberIntegration,
  //   //         access_token: data.access_token,
  //   //         refresh_token: data.refresh_token
  //   //       })
  //   //   );
  //   // }
  // }, [loading]);

  // useEffect(() => {
  //   var r = document.querySelector(':root');
  //   console.log(require('./config.json').primary);
  //   r.style.setProperty('--color-header', require('./config.json').primary);
  // }, []);

  return (
    <div>
      <ToastContainer position="bottom-right" />
      <ThemeProvider theme={theme}>
        {loading && !user && <Loader />}
        <ReactNotifications />
        <Router>
          <Routes>
            <Route
              path="login"
              element={<Login />}
            />
            <Route
              exact
              path="register"
              element={<Register />}
            />
            <Route
              exact
              path="join"
              element={<InviteLink />}
            />
            <Route
              path="logout"
              element={<Logout />}
            />
            <Route element={<ProtectedRoute />}>
              <Route
                path="/"
                element={<Sketch />}
              />
              <Route
                path="history"
                element={
                  <>
                    <Header />
                    <History />
                  </>
                }
              />
              <Route
                path="settings"
                element={
                  <>
                    <Header />
                    <Settings />
                  </>
                }
              >
                <Route
                  path="billing"
                  element={<Billing />}
                />
                <Route
                  path="branding"
                  element={<Customization />}
                />
                <Route
                  path="team"
                  element={<Team />}
                />
                <Route
                  path="jobNimbus"
                  element={<JobNimbusSettings />}
                />
                <Route
                  path="jobber"
                  element={<JobberSettings />}
                />
                <Route
                  path="support"
                  element={<Support />}
                />
              </Route>
            </Route>

            {/* <Route
            path='/reset-password'
            element={<ResetPassword />}
          /> */}
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}
