import { useState } from 'react';
import LineWithDragHandles from './LineWithDragHandles.js';

export default function Fence(props) {
  const [color, setColor] = useState('#4D4D22');

  return (
    <LineWithDragHandles
      {...props}
      //stroke={color || '#4D4D22'}
      strokeWidth={5}
      type={'Fence'}
      textColor={'#4D4D22'}
      setColor={setColor}
      //offsetY={-5}
      // shadowColor={'#D54135'}
      // shadowOffsetY={-3}
      // shadowOffsetX={5}
    />
  );
}
