import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext, setUser } from '../../../contexts/AuthContext';
import { saveUserJobberIntegrationSettings } from '../../../api/user';
import { v4 as uuid } from 'uuid';
import { Button } from '@jobber/components/Button';
import Typography from '@mui/material/Typography';

import '@jobber/design/foundation.css';

const { notify } = require('../../../utils/notification');
const { requestAccessToken, disconnectJobber } = require('../../../api/jobber');

export default function JobberSettings() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!auth.user) return;

    setSettings(auth.user.jobberIntegration || { enabled: false });
  }, [auth.user]);

  useEffect(() => {
    if (!auth.user) return;
    console.log(query.get('code'));
    if (query.get('code')) {
      setLoading(true);
      handleJobberRedirect();
    }
  }, [window.location.pathname, auth.user]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const onBlurSaveApiKey = async (e) => {
    setSettings({ ...settings, apiKey: e.target.value, enabled: true });
    const { error, response } = await saveUserJobberIntegrationSettings(auth.user.ref, { ...settings, apiKey: e.target.value, enabled: true });
    if (error) setSettings({ ...settings, enabled: false });
  };

  const onSaveToggle = async (e) => {
    setSettings({ ...settings, enabled: !settings.enabled });
    const { error, response } = await saveUserJobberIntegrationSettings(auth.user.ref, { ...settings, enabled: !settings.enabled });
    if (error) setSettings({ ...settings, enabled: false });
  };

  const handleLinkJobberButton = async () => {
    const unique_id = uuid();
    window.localStorage.setItem('stateToken', unique_id);
    window.location = `https://api.getjobber.com/api/oauth/authorize?client_id=07e36a30-7673-4308-aeaa-03f919d46885&redirect_uri=${process.env.REACT_APP_JOBBER_REDIRECT_URI}/settings/jobber&state=${unique_id}`;
    return;
  };

  const handleUnlinkJobberButton = async () => {
    setLoading(true);
    const { error, response } = await disconnectJobber(settings.access_token);

    await saveUserJobberIntegrationSettings(auth.user.ref, { ...settings, access_token: null, refresh_token: null, enabled: false });
    setSettings({ ...settings, access_token: null, refresh_token: null, enabled: false });
    setLoading(false);
    return;
  };

  const handleJobberRedirect = async () => {
    // make sure state matches
    let stateToken = window.localStorage.getItem('stateToken');
    let code = query.get('code');
    let stateParam = query.get('state');

    if (stateToken !== stateParam) return notify('danger', 'Error', `Error linking Jobber: State does not match`);

    console.log(process.env.REACT_APP_JOBBER_CLIENT_SECRET);
    const { access_token, refresh_token } = await requestAccessToken(code, process.env.REACT_APP_JOBBER_CLIENT_ID, process.env.REACT_APP_JOBBER_CLIENT_SECRET);
    console.log(access_token);
    await saveUserJobberIntegrationSettings(auth.user.ref, { ...settings, access_token: access_token, refresh_token: refresh_token, enabled: true });
    setSettings({ ...settings, access_token: access_token, refresh_token: refresh_token, enabled: true });
    navigate('/settings/jobber');
    setLoading(false);
  };

  return (
    <div className="justify-center px-10 py-5">
      <Typography variant="h1">Jobber settings</Typography>
      <div className="mb-3 w-full">
        <p className="text-xs my-2 text-onPrimaryBgSofter">Enable Jobber Integration</p>
        <label
          htmlFor="enableJobberToggle"
          className="flex relative items-center mb-4 cursor-pointer max-w-fit"
        >
          <input
            type="checkbox"
            id="enableJobberToggle"
            className="sr-only"
            checked={settings.enabled !== undefined ? settings.enabled : false}
            onClick={onSaveToggle}
          />
          <div className="w-11 h-6 bg-onPrimaryBgSofter rounded-full border border-onPrimaryBgSofter toggle-bg"></div>
          <span className="ml-3 font-medium text-onPrimaryBgSofter text-xs">{settings && settings.enabled ? settings.enabled.toString() : ''}</span>
        </label>
        <p className="text-xs my-2 text-onPrimaryBgSofter">Connect Jobber Account</p>
        <Button
          loading={loading}
          variation={settings.access_token && settings.refresh_token ? 'destructive' : ''}
          label={settings.access_token && settings.refresh_token ? 'Unlink Jobber' : 'Link Jobber'}
          onClick={!settings.access_token && !settings.refresh_token ? handleLinkJobberButton : handleUnlinkJobberButton}
        />
        {/* <input
          id="apiKeyInput"
          defaultValue={settings && settings.apiKey}
          onBlur={onBlurSaveApiKey}
          type="text"
          className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700
         focus:bg-white 
         focus:border-header 
         focus:outline-header 
         focus:shadow-none
      "
          placeholder="API Key"
        /> */}
      </div>
    </div>
  );
}
