import { useRef, useEffect, useState, forwardRef } from 'react';
import Konva from 'konva';
import { Group, Line, Circle, Text, Rect, Filters } from 'react-konva';
import { Html } from 'react-konva-utils';

import {
  TextField,
  InputAdornment,
  Box,
  FormControl,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  useMediaQuery,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Grid,
  ListItemText,
  ListItemButton,
  Divider,
  Typography,
  AppBar,
  Toolbar,
  List,
  Slide
} from '@mui/material';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';

export default function LineWithDragHandles(props) {
  const [selected, setSelected] = useState(false);
  const [isDraggingAllowed, setIsDraggingAllowed] = useState(false);
  const [feet, setFeet] = useState(props.feet || '');
  const [inches, setInches] = useState(props.inches || '');
  const [text, setText] = useState(null);
  const [fontSize, setFontSize] = useState(props.fontSize || 20);

  const [group, setGroup] = useState(props.group || props.defaultGroup);
  const stageRef = props.stageRef;
  const snapObjectToExistingObjects = props.snapObjectToExistingObjects;
  const snapPositionToGrid = props.snapPositionToGrid;
  const points = props.points;
  const groupOptions = props.groupOptions || [];
  //const [groupOptions, setGroupOptions] = useState(props.groupOptions);
  // const textX = props.textPoints.x;
  // const textY = props.textPoints.y;

  const lineRef = useRef(null);
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);

  const textRef = useRef(null);

  useEffect(() => {
    document.addEventListener('draggingAllowed', onDraggingAllowed);
    document.addEventListener('clearSelections', onClearSelections);
    //console.log(window.document.hasFocus());
    return () => {
      document.removeEventListener('draggingAllowed', onDraggingAllowed);
      document.removeEventListener('clearSelections', onClearSelections);
    };
  }, []);

  useEffect(() => {
    save();
    updateText();
  }, [inches, feet, group, fontSize]);

  useEffect(() => {
    let boundingRect = textRef.current.getSelfRect();

    textRef.current.setAttrs({
      offsetX: boundingRect.width / 2,
      offsetY: boundingRect.height / 2 + 20
    });
  }, [text]);

  const onDraggingAllowed = (e) => {
    if (e.detail.state) {
      setIsDraggingAllowed(true);
    } else {
      setIsDraggingAllowed(false);
    }
  };

  const onClearSelections = (e) => {
    setSelected(false);
  };

  const onDragMoveAnchors = (e) => {
    let x1, y1, x2, y2;

    if (e.target === anchorRef1.current) {
      let anchorPosition1 = snapObjectToExistingObjects(anchorRef1.current);
      //let anchorPosition1 = snapPositionToGrid(anchorRef1.current);
      x1 = anchorPosition1.x;
      y1 = anchorPosition1.y;
      x2 = anchorRef2.current.attrs.x;
      y2 = anchorRef2.current.attrs.y;

      anchorRef1.current.setAttrs({
        x: x1,
        y: y1
      });
    } else if (e.target === anchorRef2.current) {
      let anchorPosition2 = snapObjectToExistingObjects(anchorRef2.current);
      //let anchorPosition2 = snapPositionToGrid(anchorRef2.current);
      x1 = anchorRef1.current.attrs.x;
      y1 = anchorRef1.current.attrs.y;
      x2 = anchorPosition2.x;
      y2 = anchorPosition2.y;

      anchorRef2.current.setAttrs({
        x: x2,
        y: y2
      });
    }

    const points = [x1, y1, x2, y2];

    lineRef.current.setAttrs({
      points: points
    });

    updateText();
  };

  const onDragEndAnchors = (e) => {
    save();
  };

  const onContextMenu = (e) => {
    e.evt.preventDefault();
    const closePopoversEvent = new CustomEvent('clearSelections', {
      detail: { target: e.target }
    });
    document.dispatchEvent(closePopoversEvent);

    if (e.target === stageRef.current) {
      return;
    }

    setSelected(true);

    let objects = getLineGroups();

    objects.forEach((group) => {
      if (group.children[0].attrs.id !== lineRef.current.attrs.id) {
        group.opacity(0.2);
      } else {
        group.opacity(1);
      }
    });

    if (e.target.constructor.name === 'Arc' || 'Circle' || 'Text') return;
    scrollToLine(e.target.attrs.points);

    //lineRef.current.opacity(1);
  };

  const onClickDelete = () => {
    const shapeId = lineRef.current.id();

    props.delete(shapeId);

    props.setSaveData((prevState) => {
      let newState = { ...prevState };
      delete newState[shapeId];
      return newState;
    });

    lineRef.current.destroy();
    anchorRef1.current.destroy();
    anchorRef2.current.destroy();

    setSelected(false);

    let objects = getLineGroups();

    objects.forEach((line) => {
      line.opacity(1);
    });
  };

  const onCloseDialog = () => {
    setSelected(false);

    let objects = getLineGroups();

    objects.forEach((line) => {
      line.opacity(1);
    });
  };

  const onPreviousObject = () => {
    setSelected(false);
    let objects = getLineGroups();

    let objectToSelect = null;
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].attrs.id === props.id) {
        if (i === 0) {
          objectToSelect = objects[objects.length - 1];
        } else {
          objectToSelect = objects[i - 1];
        }
      }
    }

    if (objectToSelect !== null) {
      scrollToLine(objectToSelect.children[0].attrs.points);
      objectToSelect.children[0].dispatchEvent(new CustomEvent('contextmenu'));
    }
  };

  const onNextObject = () => {
    setSelected(false);
    //lineRef.current.opacity(0.3);

    let objects = getLineGroups();
    console.log(objects);
    let objectToSelect = null;
    for (let i = 0; i < objects.length; i++) {
      // object matches current line
      if (objects[i].attrs.id === props.id) {
        // if an object exists
        if (i === objects.length - 1) {
          objectToSelect = objects[0];
        }
        // if no object exists
        else {
          objectToSelect = objects[i + 1];
        }
      }
    }

    if (objectToSelect !== null) {
      scrollToLine(objectToSelect.children[0].attrs.points);
      objectToSelect.children[0].dispatchEvent(new CustomEvent('contextmenu'));
      // objectToSelect.children[0].opacity(1);
    }
  };

  const getLineGroups = () => {
    let objects = stageRef.current.children[1].children;

    objects = objects.filter((object) => object.getClassName() === 'Group' && object.attrs.scrollable);

    return objects;
  };

  const save = () => {
    if (props.setSaveData) {
      props.setSaveData({
        [props.id]: {
          id: props.id,
          key: props.id,
          type: props.type,
          points: lineRef.current.attrs.points,
          inches: inches,
          feet: feet,
          group: group,
          fontSize: fontSize,
          textPoints: { x: textRef.current.attrs.x, y: textRef.current.attrs.y }
        }
      });
    }
  };

  const getAngle = (x1, y1, x2, y2) => {
    var dy = y2 - y1;
    var dx = x2 - x1;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta <= -90) {
      theta = 180 + theta;
    } else if (theta >= 90) {
      theta = theta - 180;
    }
    return theta;
  };

  const updateText = () => {
    let points = lineRef.current.attrs.points;

    let rotation = getAngle(points[0], points[1], points[2], points[3]);
    let x = (points[0] + points[2]) / 2;
    let y = (points[1] + points[3]) / 2;

    textRef.current.setAttrs({
      x: x,
      y: y,
      rotation: rotation
    });

    let text = [];

    if (feet !== '' && feet > 0) {
      text.push(`${feet}'`);
    }
    if (inches !== '' && inches > 0) {
      text.push(`${inches}''`);
    }
    if (text.length > 0) {
      text = text.join(' ');
    } else {
      text = null;
    }

    setText(text);
    let boundingRect = textRef.current.getSelfRect();

    textRef.current.setAttrs({
      offsetX: boundingRect.width / 2,
      offsetY: boundingRect.height / 2 + 20
    });
  };

  const onFeetChange = (newValue) => {
    // Remove non-numeric characters using a regular expression
    newValue = newValue.replace(/\D/g, '');

    // Convert the string value to a number
    newValue = parseInt(newValue);

    // Check if the value is negative or NaN (e.g., if the input was empty)
    if (isNaN(newValue) || newValue < 0) {
      // Set the value to 0 if it's negative or NaN
      newValue = '';
    }

    // Update the state with the filtered and validated value
    setFeet(newValue);
  };

  const onInchesChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/\D/g, '');

    if (newValue < 0) {
      setInches(0);
    } else if (newValue > 12) {
      setInches(12);
    } else {
      setInches(newValue);
    }
  };

  const scrollToLine = (points) => {
    // Calculate the centroid of the shape using the points
    const centerX = (points[0] + points[2]) / 2;
    const centerY = (points[1] + points[3]) / 2;

    // Get the dimensions of the viewport
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the position to center the shape on the stage
    const stageCenterX = viewportWidth / 2 + window.scrollX;
    const stageCenterY = viewportHeight / 2 + window.scrollY;
    const offsetX = stageCenterX - centerX;
    const offsetY = stageCenterY - centerY;

    // Update the position of the stage
    stageRef.current.position({ x: offsetX, y: offsetY });
    stageRef.current.batchDraw();
  };

  const determineColor = (type, groupColor) => {
    // const fenceLineMenu = document.getElementById(`fence-line-menu-${props.id}`);
    // const ariaHidden = fenceLineMenu && fenceLineMenu.getAttribute('aria-hidden');

    // console.log(fenceLineMenu);
    // if (!selected && !fenceLineMenu) {
    //   return '#ACA7A6';
    // }
    if (type === 'Fence') {
      return groupColor;
    } else if (type === 'Tear-Out') {
      return 'red';
    } else if (type === 'Existing') {
      return 'black';
    }
  };

  //const isTablet = useMediaQuery('(max-width: 1024px)');

  return (
    <Group
      key={props.id}
      type={props.type}
      id={props.id}
      scrollable={true}
    >
      <Line
        {...props}
        ref={lineRef}
        hitStrokeWidth={20}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
        draggable={false}
        points={points}
        group={group}
        stroke={determineColor(props.type, group.color)}
        shadowColor={selected ? 'black' : 'transparent'}
        shadowOpacity={selected ? 0.2 : 0}
        shadowBlur={selected ? 10 : 0}
      ></Line>
      <Circle
        x={points[0]}
        y={points[1]}
        radius={4}
        fill={'#FFFFFF'}
        stroke={'#D54135'}
        strokeWidth={4}
        fillAfterStrokeEnabled={true}
        draggable={isDraggingAllowed}
        onDragMove={onDragMoveAnchors}
        onDragEnd={onDragEndAnchors}
        ref={anchorRef1}
        type={'dragHandle'}
        hitStrokeWidth={5}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
      ></Circle>
      <Circle
        x={points[2]}
        y={points[3]}
        radius={4}
        fill={'#FFFFFF'}
        stroke={'#D54135'}
        strokeWidth={4}
        fillAfterStrokeEnabled={true}
        draggable={isDraggingAllowed}
        onDragMove={onDragMoveAnchors}
        onDragEnd={onDragEndAnchors}
        ref={anchorRef2}
        type={'dragHandle'}
        hitStrokeWidth={5}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
      ></Circle>

      <Text
        text={text}
        x={(points[0] + points[2]) / 2}
        y={(points[1] + points[3]) / 2}
        draggable={true}
        ref={textRef}
        rotation={getAngle(points[0], points[1], points[2], points[3])}
        visible={text != null}
        padding={3}
        fill={determineColor(props.type, group.color)}
        fontStyle={'bold'}
        // shadowEnabled={true}
        // shadowColor={'#151515'}
        // shadowBlur={0.5}
        fontSize={fontSize}
        hitStrokeWidth={5}
        onDragEnd={(e) => save()}
        manuallyMoved={false}
        onContextMenu={onContextMenu}
      />
      <Html>
        <Dialog
          id={`fence-line-menu-${props.id}`}
          open={selected}
          onClose={onCloseDialog}
          TransitionComponent={Transition}
          maxWidth={'lg'}
          fullWidth={true}
          className="!bg-transparent !bg-none"
          PaperProps={{ elevation: 0 }}
          sx={{
            //You can copy the code below in your theme

            '& .MuiBackdrop-root': {
              backgroundColor: 'transparent' // Try to remove this to see the result
            }
          }}
        >
          <IconButton
            size="large"
            aria-label="Previous Object"
            onClick={onPreviousObject}
            className="fixed left-12 bg-white !z-[9999999] shadow-md"
            color="white"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            size="large"
            aria-label="Next Object"
            onClick={onNextObject}
            className="fixed right-12 bg-white !z-[9999999] shadow-md"
          >
            <NavigateNextIcon />
          </IconButton>

          <AppBar
            sx={{ position: 'fixed', top: 'auto', bottom: 0, opacity: 1, backgroundColor: '#FFFFFF', height: '100px' }}
            className=" justify-center"
          >
            <Toolbar>
              {/* Edit: {props.type} */}

              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    id="feet"
                    label="Feet"
                    autoComplete="off"
                    value={feet}
                    onChange={(e) => {
                      // Remove non-numeric characters using a regular expression
                      const numericValue = e.target.value.replace(/\D/g, '');
                      onFeetChange(numericValue); // Update the state with the filtered value
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onNextObject();
                      }
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                      inputProps: {
                        min: 0,
                        autoFocus: true,
                        pattern: '[0-9]*',
                        type: 'number',
                        inputMode: 'numeric'
                        //inputmode: 'numeric'
                      },
                      pattern: '[0-9]*',
                      inputMode: 'numeric',
                      type: 'number'
                    }}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    id="inches"
                    label="Inches"
                    value={inches}
                    onChange={(e) => onInchesChange(e)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">in</InputAdornment>,
                      inputProps: {
                        min: 0,
                        max: 12
                      },
                      inputMode: 'numeric'
                    }}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={`${props.type === 'Fence' ? `` : 'hidden'} self-center mt-[8px]`}
                >
                  <FormControl sx={{ minWidth: 220 }}>
                    <InputLabel id="group-label">Group</InputLabel>
                    <Select
                      labelId="group-label"
                      id="group"
                      label="Group"
                      value={JSON.stringify(group)}
                      onChange={(e) => {
                        let value = JSON.parse(e.target.value);
                        setGroup(value);
                        props.setDefaultGroup(value);
                        props.setColor(value.color);
                      }}
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>Select a group...</em>
                      </MenuItem>
                      {groupOptions.map((option) => (
                        <MenuItem
                          key={option.name}
                          value={JSON.stringify(option)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <DialogActions className="flex-auto">
                <Button
                  color="error"
                  id="delete-button"
                  onClick={onClickDelete}
                >
                  Delete
                </Button>
                <Button
                  id="done"
                  onClick={onCloseDialog}
                >
                  Done
                </Button>
              </DialogActions>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Html>
    </Group>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});
