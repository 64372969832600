import React, { useRef, useEffect, useState } from 'react';
import { Circle, Transformer } from 'react-konva';
import { styled } from '@mui/material/styles';

const ContextMenu = styled('div')`
  display: none;
  position: absolute;
  width: 220px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
`;

const ContextMenuButton = styled('button')`
  width: 100%;
  background-color: white;
  border: none;
  margin: 0;
  padding: 10px;
  :hover {
    background-color: lightgray;
  }
`;

const Tree = (props) => {
  const [showTransformer, setShowTransformer] = useState(false);
  const [selected, setSelected] = useState(false);

  const circleRef = useRef(null);
  const transformerRef = useRef(null);

  useEffect(() => {
    document.addEventListener('clearSelections', onClearSelections);

    return () => {
      document.removeEventListener('clearSelections', onClearSelections);
    };
  }, []);

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    if (showTransformer) {
      transformerRef.current.nodes([circleRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [showTransformer]);

  const onClearSelections = (e) => {
    const target = e.detail.target;
    const hasParent = Boolean(target.parent);
    let hasNodes = false;
    if (hasParent) {
      hasNodes = Boolean(target.parent._nodes);
    }

    if (target === circleRef.current) {
      // setSelected(true);
      // setShowTransformer(true);
      // props.setSelected(circleRef);
      //skip
    } else if (hasParent && hasNodes && target.parent._nodes[0] === circleRef.current) {
      //skip
    } else {
      setSelected(false);
      setShowTransformer(false);
    }
  };

  const onSelect = (e) => {
    if (e.evt.button === 0 || e.evt.touches) {
      setShowTransformer(true);
      setSelected(true);
      props.setSelected(circleRef);
    }
  };

  const onTap = (e) => {
    if (e.evt.touches.length > 1) {
      //onContextMenu(e);
    } else {
      onSelect(e);
    }
  };

  const onDragEnd = (e) => {
    save();
  };

  const onTransformEnd = (e) => {
    save();
  };

  const save = () => {
    //console.log(circleRef.current.attrs);
    if (props.setSaveData) {
      return props.setSaveData({
        [props.id]: {
          id: props.id,
          key: props.id,
          type: 'Tree',
          x: circleRef.current.attrs.x,
          y: circleRef.current.attrs.y,
          radius: circleRef.current.attrs.radius,
          scaleX: circleRef.current.attrs.scaleX || 1,
          scaleY: circleRef.current.attrs.scaleY || 1,
          rotation: circleRef.current.attrs.rotation
        }
      });
    }
  };

  return (
    <>
      <Circle
        {...props}
        onClick={onSelect}
        ref={circleRef}
        draggable={showTransformer}
        strokeScaleEnabled={false}
        onTouchStart={onTap}
        onDragEnd={onDragEnd}
        onTransformEnd={onTransformEnd}
        fill={'#659265'}
        opacity={0.5}
        stroke={'#003300'}
        strokeWidth={5}
      />
      {showTransformer && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {/* <Html>
        <ContextMenu
          id="menu"
          ref={menuRef}
        >
          {selected && (
            <>
              <ContextMenuButton onClick={onClickDuplicate}>Duplicate</ContextMenuButton>
              <ContextMenuButton onClick={onClickDelete}>Delete</ContextMenuButton>
            </>
          )}
        </ContextMenu>
      </Html> */}
    </>
  );
};

export default Tree;
