import React, { useEffect, useState } from 'react';
import { updateDoc, arrayUnion, getDocs, doc, collection, addDoc } from 'firebase/firestore';
import { db } from '../../api/firebase';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../api/user';
import moment from 'moment';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import DeleteDrawing from './components/DeleteDrawing';
import LoadDrawing from './components/LoadDrawing';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, Box, Button } from '@mui/material';
const { deleteDrawing } = require('../../api/drawing.js');
const { notify } = require('../../utils/notification.js');

export default function History() {
  const auth = useSelector((state) => state.auth);
  const organization = useSelector((state) => state.organization);
  const [drawings, setDrawings] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [contactFilter, setContactFilter] = useState(null);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedFilter, setSelectedFilter] = useState('Everyone');

  useEffect(async () => {
    if (!organization.id) return; // If organizationId is not set, return early
    console.log(organization);
    // Fetch the drawings from the organization's "drawings" subcollection
    let drawingsData = await getDrawings();
    // Set the initial itemOffset and endOffset correctly
    setItemOffset(0);
    setEndOffset(itemsPerPage);

    console.log(drawingsData);
    setCurrentItems(drawingsData);
    setDrawings(drawingsData);
  }, [organization.ref, itemsPerPage]);

  useEffect(() => {
    setEndOffset(itemOffset + itemsPerPage);

    // Apply the filter to the drawings before slicing based on itemOffset and endOffset
    const filteredDrawings = drawings.filter((drawing) => {
      if (!contactFilter && !selectedFilter) return true;
      if (selectedFilter === 'Everyone' && !contactFilter) return true;

      if (selectedFilter === 'Everyone' && contactFilter) {
        if (drawing.contact === 'null') return false;
        if (!drawing.projectName) return false;
        console.log(contactFilter);

        const projectName = drawing.projectName;
        console.log(projectName);
        return projectName.toLowerCase().includes(contactFilter.toLowerCase());
      }

      if (!contactFilter && selectedFilter) {
        if (drawing.creator === 'null') return false;
        const creator = drawing.creator;
        return creator.toLowerCase().includes(selectedFilter.toLowerCase());
      }
    });

    setPageCount(Math.ceil(filteredDrawings.length / itemsPerPage));
    setCurrentItems(filteredDrawings.slice(itemOffset, itemOffset + itemsPerPage));
  }, [drawings.length, currentItems.length, itemOffset, contactFilter, selectedFilter, itemsPerPage]);

  useEffect(() => {
    // Update current items whenever itemsPerPage changes
    const startIndex = page * itemsPerPage;
    setCurrentItems(drawings.slice(startIndex, startIndex + itemsPerPage));
  }, [itemsPerPage]);

  const getDrawings = async () => {
    const drawingsCollectionRef = collection(organization.ref, 'drawings');
    const drawingsSnapshot = await getDocs(drawingsCollectionRef);
    const drawingsData = drawingsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log(drawingsData);
    return drawingsData;
  };

  const handleDeleteDrawing = async (e) => {
    try {
      // Delete the drawing from the "drawings" subcollection
      await deleteDrawing(organization.ref, e.id);

      // Fetch the latest drawings from the organization's "drawings" subcollection
      await getDrawings().then((res) => {
        console.log(res);
        setDrawings(res);
        setCurrentItems(res);
      });

      // Update the state with the latest drawings
    } catch (error) {
      console.error('Error deleting drawing:', error);
    }
  };

  const handleLoadDrawing = (e) => {
    console.log(e);

    window.location = `/?id=${e.id}`;
  };
  const handleChangePage = (event, newPage) => {
    const newOffset = newPage * itemsPerPage;
    setItemOffset(newOffset);
    setPage(newPage);
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleContactFilterChange = (event) => {
    setContactFilter(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page when the rows per page is changed
  };

  // Function to filter drawings based on the selected filter
  const filterDrawings = () => {
    if (selectedFilter === 'Everyone') {
      // Show all drawings
      return drawings;
    } else {
      // Filter drawings to show only those of team members
      const userEmails = [auth.user.email /* Add other team members' emails here */];
      return drawings.filter((drawing) => userEmails.includes(drawing.creator));
    }
  };

  // Use the filtered drawings in the rest of the component
  const filteredDrawings = filterDrawings();

  return (
    <div className="container mx-auto mt-5 lg:mt-5 px-5 scroller pt-[64px]">
      <header className="bg-white h-auto sticky top-0"></header>
      <div className="flex justify-between text-primaryText font-sans font-medium">
        <div className="self-center">
          <span className="font-bold font-sans">Drawing History</span>
        </div>
        <div className="min-w-1/4  text-right flex">
          <TextField
            id="search-by-contact"
            type="text"
            placeholder="Search"
            variant="outlined"
            size="small"
            value={contactFilter}
            onChange={handleContactFilterChange}
          />
          <select
            value={selectedFilter}
            onChange={handleFilterChange}
            className="ml-2 px-2 py-1 border rounded bg-white text-primaryText"
          >
            <option value="Everyone">Everyone</option>
            {organization?.members?.map((member) => (
              <option value={member?.name}>{member?.name}</option>
            ))}
            {/* Add options for team members based on the organization */}
            {/* Example: <option value="team-member-email">Team Member Name</option> */}
          </select>
        </div>
      </div>
      <div className="divide-solid bg-primaryText h-[.7px] mt-3"></div>
      <div className="mt-10 h-4xl w-full">
        <div className="rounded-xl relative scroller">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Preview</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems
                  .sort((a, b) => b.lastUpdated - a.lastUpdated)
                  .map((e, index) => (
                    <TableRow
                      key={index}
                      className="text-primaryText text-xs border-b border-onPrimaryBgSofter"
                    >
                      <TableCell>
                        <Zoom>
                          <img
                            src={e.thumbnail}
                            className="border-[1px]"
                            width="50px"
                            alt={`Drawing ${index + 1}`}
                          />
                        </Zoom>
                      </TableCell>
                      <TableCell className="truncate">
                        <span className="flex justify-left font-sans">
                          {e.contact !== 'null' && e.contact ? JSON.parse(e.contact).display_name : e.projectName ? e.projectName : null}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className="flex justify-left font-sans">{moment(e.lastUpdated).format('MMMM Do YYYY, h:mm:ss a')}</span>
                      </TableCell>
                      <TableCell>
                        <span className="flex justify-left font-sans">{e.creator || ''}</span>
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-evenly">
                          <LoadDrawing
                            drawing={e}
                            loadDrawing={handleLoadDrawing}
                          />
                          <DeleteDrawing
                            drawing={e}
                            deleteDrawing={handleDeleteDrawing}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Box
          display="flex"
          justifyContent="center"
          mt={3}
        >
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 50]}
            count={drawings.length}
            rowsPerPage={itemsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </div>
    </div>
  );
}
