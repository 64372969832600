import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { deleteObject, useSketchContext, resetState } from '../../../contexts/SketchContext';
import DropdownMenu from '../../../components/DropdownMenu';
import ContactSearchBar from '../../../components/ContactSearchBar';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Popper from '@mui/material/Popper';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreIcon from '@mui/icons-material/MoreVert';
import FenceIcon from '@mui/icons-material/Fence';
import InterestsIcon from '@mui/icons-material/Interests';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import ImageIcon from '@mui/icons-material/Image';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
import CallMadeIcon from '@mui/icons-material/CallMade';
import SquareIcon from '@mui/icons-material/Square';
import HomeIcon from '@mui/icons-material/Home';
import CircleIcon from '@mui/icons-material/Circle';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SaveIcon from '@mui/icons-material/Save';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import DraggableImage from './DraggableImage.js';

import thunderboltSvg from '../../../assets/thunderboltSvg.svg';
import solarSvg from '../../../assets/solarSvg.svg';
import dogSvg from '../../../assets/dogSvg.svg';
import gasMeterSvg from '../../../assets/gasMeterSvg.svg';
import treeSvg from '../../../assets/treeSvg.svg';
import slideMotorSvg from '../../../assets/slideMotorSvg.svg';
import singleGateSvg from '../../../assets/door-swing-90.svg';

const HiddenInput = styled('input')`
  display: none;
`;

export default function KonvaToolsAppBar(props) {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [address, setAddress] = useState(null);
  const [mapType, setMapType] = useState('satellite');
  const [zoomLevel, setZoomLevel] = useState(20);
  const [mapHeight, setMapHeight] = useState(600);
  const [mapWidth, setMapWidth] = useState(600);
  const [saveButtonOpen, setSaveButtonOpen] = useState(false);
  const [saveButtonTargetElement, setSaveButtonTargetElement] = useState(null);
  const [fenceButtonOpen, setFenceButtonOpen] = useState(false);
  const [fenceButtonTargetElement, setFenceButtonTargetElement] = useState(null);
  const [gateButtonOpen, setGateButtonOpen] = useState(false);
  const [gateButtonTargetElement, setGateButtonTargetElement] = useState(null);
  const [symbolButtonOpen, setSymbolButtonOpen] = useState(false);
  const [symbolButtonTargetElement, setSymbolButtonTargetElement] = useState(null);
  const [showClearConfirmModal, setShowClearConfirmModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const setObjects = props.setObjects;
  const currentTool = props.currentTool;
  const setCurrentTool = props.setCurrentTool;
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copyValue, setCopyValue] = useState('');
  const [copied, setCopied] = useState(false);

  const auth = useSelector((state) => state.auth);
  const sketch = useSelector((state) => state.sketch);
  const contact = useSelector((state) => state.sketch.contact);
  const organization = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (!contact) return;
    const { address_line1, city, state_text, zip } = contact;

    let contactAddress = `${address_line1} ${city}, ${state_text} ${zip}`;

    setAddress(contactAddress);
  }, [contact]);

  const changeTool = (tool) => {
    console.log('changing tool: ', tool);
    handleMobileMenuClose();
    setCurrentTool(tool);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleCancel = () => {
    setOpenDialog(false);
  };
  const handleConfirm = () => {
    // setAddress(contactAddress);
    // console.log(contactAddress);
    if (address && address.length) {
      const id = props.getRandomId();
      const url = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
        address
      )}&zoom=${zoomLevel}&size=${mapHeight}x${mapWidth}&maptype=${mapType}&scale=2&key=AIzaSyCJ_mDLudxNumoUY4X7qqZ8eaezqjMUne0`;
      setObjects([
        <DraggableImage
          key={id}
          url={url}
          id={id}
          saveData={props.saveData}
          setSaveData={props.setSaveData}
          zoomLevel={zoomLevel}
          mapHeight={mapHeight}
          mapWidth={mapWidth}
          mapType={mapType}
          objects={props.objects}
          setObjects={setObjects}
          delete={() => props.deleteObject(id)}
          setSelected={props.setSelected}
          grayscaleFilter={true}
        />,
        ...props.objects
      ]);
    }

    setOpenDialog(false);
    // changeTool('fence');
    // changeTool('pan');
  };
  const onAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const onMapTypeChange = (e) => {
    setMapType(e.target.value);
  };

  const onMapZoomChange = (e) => {
    setZoomLevel(e.target.value);
  };

  const onMapHeightChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/\D/g, '');

    if (newValue < 0) {
      setMapHeight(0);
    } else if (newValue > 640) {
      setMapHeight(640);
    } else {
      setMapHeight(newValue);
    }
  };

  const onMapWidthChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/\D/g, '');

    if (newValue < 0) {
      setMapWidth(0);
    } else if (newValue > 640) {
      setMapWidth(640);
    } else {
      setMapWidth(newValue);
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const mobileMenuId = 'konva-tools-appbar-menu-mobile';

  const returnButtonColor = (tool) => {
    if (Array.isArray(tool)) {
      return tool.includes(currentTool) ? 'primary' : 'inherit';
    } else {
      return tool === currentTool ? 'primary' : 'inherit';
    }
  };

  const onFileUpload = (e) => {
    const files = e.target.files;

    // FileReader support
    if (FileReader && files && files.length) {
      let reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;

        const id = props.getRandomId();
        setObjects([
          <DraggableImage
            key={id}
            image={image}
            id={id}
            saveData={props.saveData}
            setSaveData={props.setSaveData}
            objects={props.objects}
            setObjects={setObjects}
            delete={() => props.deleteObject(id)}
            setSelected={props.setSelected}
            grayscaleFilter={false}
          />,
          ...props.objects
        ]);
      };
      reader.readAsDataURL(files[0]);
    }
    setOpenDialog(false);
  };

  const PanToolButton = (
    <IconButton
      size="large"
      aria-label="Pan Tool"
      color={returnButtonColor('pan')}
      onClick={() => changeTool('pan')}
    >
      <PanToolAltIcon />
    </IconButton>
  );

  const ImageButton = (
    <IconButton
      component="span"
      size="large"
      aria-label="Image Tool"
      color={returnButtonColor('image')}
      onClick={handleClickOpen}
    >
      <ImageIcon />
    </IconButton>
  );

  const FenceToolButtonMobile = (
    <IconButton
      size="large"
      aria-label="Fence Tool"
      color={returnButtonColor('fence')}
      onClick={() => changeTool('fence')}
    >
      <FenceIcon />
    </IconButton>
  );

  const TearOutFenceToolButtonMobile = (
    <IconButton
      size="large"
      aria-label="Tear-Out Fence Tool"
      color={returnButtonColor('tearout')}
      onClick={() => changeTool('tearout')}
    >
      <FenceIcon />
    </IconButton>
  );

  const ExistingFenceToolButtonMobile = (
    <IconButton
      size="large"
      aria-label="Existing Fence Tool"
      color={returnButtonColor('existing')}
      onClick={() => changeTool('existing')}
    >
      <FenceIcon />
    </IconButton>
  );

  const FenceToolButton = (
    <>
      <IconButton
        size="large"
        aria-label="Fence Tool"
        color={returnButtonColor(['fence', 'tearout', 'existing'])}
        onClick={(e) => handleToggleFenceButton(e)}
      >
        <FenceIcon />
      </IconButton>
      <Popper
        open={fenceButtonOpen}
        anchorEl={fenceButtonTargetElement}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={(e) => handleCloseFenceButton(e)}>
          <Paper elevation={3}>
            <MenuList id="fence-menu">
              <MenuItem
                onClick={() => {
                  changeTool('fence');
                  setFenceButtonOpen(false);
                }}
              >
                Fence
                <img
                  className="w-10 ml-3"
                  src={require('../../../assets/fenceLine.png')}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeTool('tearout');
                  setFenceButtonOpen(false);
                }}
              >
                Tear-Out Fence
                <img
                  className="w-10 ml-3"
                  src={require('../../../assets/tearOutFence.png')}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeTool('existing');
                  setFenceButtonOpen(false);
                }}
              >
                Existing Fence
                <img
                  className="w-10 ml-3"
                  src={require('../../../assets/existingFenceLine.png')}
                />
              </MenuItem>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );

  const GateToolButton = (
    <>
      <IconButton
        size="large"
        aria-label="Gate Tool"
        color={returnButtonColor(['gate', 'double-gate', 'sliding'])}
        onClick={(e) => handleToggleGateButton(e)}
      >
        <DoorSlidingIcon />
      </IconButton>
      <Popper
        open={gateButtonOpen}
        anchorEl={gateButtonTargetElement}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={(e) => handleCloseGateButton(e)}>
          <Paper elevation={3}>
            <MenuList id="gate-menu">
              <MenuItem
                id="1"
                onClick={() => {
                  changeTool('gate');
                  setGateButtonOpen(false);
                }}
              >
                Single Gate
                <img
                  className="w-5 ml-3"
                  src={singleGateSvg}
                />
              </MenuItem>
              <MenuItem
                id="2"
                onClick={() => {
                  changeTool('double-gate');
                  setGateButtonOpen(false);
                }}
              >
                Double Gate
                <img
                  id="1"
                  className="w-5 ml-3"
                  src={singleGateSvg}
                />
                <img
                  id="2"
                  className="w-5 transform -scale-x-100"
                  src={singleGateSvg}
                />
              </MenuItem>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );

  const GateToolButtonMobile = (
    <IconButton
      size="large"
      aria-label="Gate Tool"
      color={returnButtonColor('gate')}
      onClick={() => changeTool('gate')}
    >
      <DoorSlidingIcon />
    </IconButton>
  );

  const DoubleGateToolButtonMobile = (
    <IconButton
      size="large"
      aria-label="Double Gate Tool"
      color={returnButtonColor('double-gate')}
      onClick={() => changeTool('double-gate')}
    >
      <DoorSlidingIcon />
    </IconButton>
  );

  const ArrowToolButton = (
    <IconButton
      size="large"
      aria-label="Arrow Tool"
      color={returnButtonColor('arrow')}
      onClick={() => changeTool('arrow')}
    >
      <CallMadeIcon />
    </IconButton>
  );

  const RectangleToolButton = (
    <IconButton
      size="large"
      aria-label="Rectangle Tool"
      color={returnButtonColor('rectangle')}
      onClick={() => changeTool('rectangle')}
    >
      <HomeIcon />
    </IconButton>
  );

  const CircleToolButton = (
    <IconButton
      size="large"
      aria-label="Circle Tool"
      color={returnButtonColor('circle')}
      onClick={() => changeTool('circle')}
    >
      <CircleIcon />
    </IconButton>
  );

  const SymbolToolButton = (
    <>
      <IconButton
        size="large"
        aria-label="Symbol Tool"
        color={returnButtonColor(['tree', 'pool', 'electricity'])}
        onClick={(e) => handleToggleSymbolButton(e)}
      >
        <InterestsIcon />
      </IconButton>
      <Popper
        open={symbolButtonOpen}
        anchorEl={symbolButtonTargetElement}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={(e) => handleCloseSymbolButton(e)}>
          <Paper elevation={3}>
            <MenuList id="symbol-menu">
              <MenuItem
                onClick={() => {
                  changeTool('tree');
                  setSymbolButtonOpen(false);
                }}
              >
                Tree
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  className=" ml-3"
                  viewBox="0 0 169 169"
                  fill="none"
                >
                  <circle
                    cx="84.5"
                    cy="84.5"
                    r="84.5"
                    fill="#659265"
                    fill-opacity="0.5"
                  />
                  <circle
                    cx="84.5"
                    cy="84.5"
                    r="82"
                    stroke="#003300"
                    stroke-opacity="0.5"
                    stroke-width="5"
                  />
                </svg>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeTool('pool');
                  setSymbolButtonOpen(false);
                }}
              >
                Pool
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  viewBox="0 0 281 141"
                  fill="none"
                  className=" ml-3"
                >
                  <rect
                    width="281"
                    height="141"
                    fill="#4ABDAC"
                    fill-opacity="0.5"
                  />
                  <rect
                    x="2.5"
                    y="2.5"
                    width="276"
                    height="136"
                    stroke="#003333"
                    stroke-opacity="0.5"
                    stroke-width="5"
                  />
                </svg>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeTool('concrete');
                  setSymbolButtonOpen(false);
                }}
              >
                Concrete
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  viewBox="0 0 281 141"
                  fill="none"
                  className="ml-3"
                >
                  <rect
                    width="281"
                    height="141"
                    fill="#B7B7B7"
                    fill-opacity="0.5"
                  />
                  <rect
                    x="2.5"
                    y="2.5"
                    width="276"
                    height="136"
                    stroke="#003333"
                    stroke-opacity="0.5"
                    stroke-width="5"
                  />
                </svg>
              </MenuItem>
              <MenuItem onClick={() => addImageToCanvas(thunderboltSvg)}>
                Electricity
                <img
                  className="w-3 ml-3"
                  src={require('../../../assets/thunderbolt.png')}
                />
              </MenuItem>
              <MenuItem onClick={() => addImageToCanvas(solarSvg)}>
                Solar Panel
                <img
                  className="w-7 ml-3"
                  src={solarSvg}
                />
              </MenuItem>
              <MenuItem onClick={() => addImageToCanvas(gasMeterSvg)}>
                Gas Meter
                <img
                  className="w-6 ml-3"
                  src={gasMeterSvg}
                />
              </MenuItem>
              <MenuItem onClick={() => addImageToCanvas(treeSvg)}>
                Tree
                <img
                  className="w-6 ml-3"
                  src={treeSvg}
                />
              </MenuItem>
              <MenuItem onClick={() => addImageToCanvas(dogSvg)}>
                Dog
                <img
                  className="w-6 ml-3"
                  src={dogSvg}
                />
              </MenuItem>
              <MenuItem onClick={() => addImageToCanvas(slideMotorSvg)}>
                Slide Motor
                <img
                  className="w-6 ml-3"
                  src={slideMotorSvg}
                />
              </MenuItem>
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );

  const TextToolButton = (
    <IconButton
      size="large"
      aria-label="Text Tool"
      color={returnButtonColor('text')}
      onClick={() => changeTool('text')}
    >
      <TextFieldsIcon />
    </IconButton>
  );

  const handleCopy = async () => {
    //console.log(props.copy);
    const dataURL = await props.copy();
    //console.log(dataURL);
    navigator.clipboard.writeText(`<img width="100%" src="${dataURL}"/>`);
    setAnchorEl(null);
    setSaveButtonOpen(false);
  };

  const handleUpload = () => {
    if (searchParams.get('id')) {
      props.upload();
      props.save(contact);
    } else {
      props.upload();
      props.saveAsNew(contact);
    }
    setAnchorEl(null);
    setSaveButtonOpen(false);
  };

  const handleSave = () => {
    if (searchParams.get('id')) {
      //props.upload();
      props.save(contact);
    } else {
      //props.upload();
      props.saveAsNew(contact);
    }
    setAnchorEl(null);
    setSaveButtonOpen(false);
  };

  const handleClear = () => {
    dispatch(resetState());
    props.clearSaveData();
    setObjects([]);
    setShowClearConfirmModal(false);
    setAnchorEl(null);
  };

  function userHasIntegrationEnabled(userData) {
    let integrationsEnabled = [];

    Object.keys(userData).forEach((e, index) => {
      if (e.includes('Integration') && userData[e].enabled) {
        integrationsEnabled.push(userData[e]);
      }
    });
    //console.log(integrationsEnabled);
    if (integrationsEnabled.length > 0) {
      return true;
    } else return false;
  }

  const SaveButton = (
    <>
      <IconButton
        id="basic-button"
        size="large"
        aria-label="Text Tool"
        color="inherit"
        // onClick={(e) => {
        //   console.log(e);
        //   setSaveButtonTargetElement(e.currentTarget);
        //   setSaveButtonOpen((prevOpen) => !prevOpen);
        // }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <SaveIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <MenuItem
          className={`font-sans font-medium text-md`}
          onClick={handleCopy}
        >
          Copy
        </MenuItem> */}
        {/* <MenuItem
          className={`${auth.user && userHasIntegrationEnabled(auth.user) ? 'font-sans font-medium text-md' : '!invisible !h-0 !p-0'}`}
          onClick={handleUpload}
        >
          Upload
        </MenuItem> */}
        <MenuItem
          className="font-sans font-medium text-md"
          onClick={handleSave}
        >
          Save
        </MenuItem>
        <MenuItem
          className="font-sans font-medium text-md"
          onClick={() => (
            props.download(sketch.saveData.stageRef.current.attrs.width, sketch.saveData.stageRef.current.attrs.height, sketch.gridVisible, sketch.gridSize),
            setAnchorEl(null)
          )}
        >
          Download
        </MenuItem>
        <MenuItem
          className="font-sans font-medium text-md"
          onClick={() => setShowClearConfirmModal(true)}
        >
          Clear
        </MenuItem>
      </Menu>
    </>
  );

  const handleCloseSaveButton = (event) => {
    if (saveButtonTargetElement && saveButtonTargetElement.contains(event.target)) {
      return;
    }

    setSaveButtonOpen(false);
  };

  const handleToggleFenceButton = (event) => {
    setFenceButtonTargetElement(event.currentTarget);
    setFenceButtonOpen((prevOpen) => !prevOpen);
  };

  const handleToggleGateButton = (event) => {
    setGateButtonTargetElement(event.currentTarget);
    setGateButtonOpen((prevOpen) => !prevOpen);
  };

  const handleToggleSymbolButton = (event) => {
    setSymbolButtonTargetElement(event.currentTarget);
    setSymbolButtonOpen((prevOpen) => !prevOpen);
  };

  const handleCloseFenceButton = (event) => {
    if (fenceButtonTargetElement && fenceButtonTargetElement.contains(event.target)) {
      return;
    }

    setFenceButtonOpen(false);
  };

  const handleCloseGateButton = (event) => {
    if (gateButtonTargetElement && gateButtonTargetElement.contains(event.target)) {
      return;
    }

    setGateButtonOpen(false);
  };

  const handleCloseSymbolButton = (event) => {
    if (symbolButtonTargetElement && symbolButtonTargetElement.contains(event.target)) {
      return;
    }

    setSymbolButtonOpen(false);
  };

  const addImageToCanvas = async (image) => {
    const id = props.getRandomId();

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    // Calculate the center coordinates
    const centerX = viewportWidth / 2;
    const centerY = viewportHeight / 2;

    const imageSize = await getMeta(image)
      .then(({ width, height }) => {
        return { height: height, width: width };
      })
      .catch((err) => {
        console.error('Error loading image:', err);
      });

    setObjects([
      <DraggableImage
        key={id}
        id={id}
        saveData={props.saveData}
        setSaveData={props.setSaveData}
        url={image}
        objects={props.objects}
        setObjects={setObjects}
        delete={() => props.deleteObject(id)}
        setSelected={props.setSelected}
        x={centerX - imageSize.width / 2}
        y={centerY - imageSize.height / 2}
      />,
      ...props.objects
    ]);
    setSymbolButtonOpen(false);
  };

  const getMeta = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  };

  const onLoad = (event) => {
    const data = event.detail.data;
    let newImage = null;
    for (var i = 0; i < data.length; i++) {
      if (data[i].type === 'DraggableImage') {
        newImage = (
          <DraggableImage
            key={data[i].key}
            url={data[i].url}
            id={data[i].id}
            setSaveData={props.setSaveData}
            saveData={props.saveData}
            x={data[i].x}
            y={data[i].y}
            zoomLevel={data[i].zoomLevel}
            mapHeight={data[i].mapHeight}
            mapWidth={data[i].mapWidth}
            mapType={data[i].mapType}
            objects={props.objects}
            setObjects={setObjects}
            delete={(id) => props.deleteObject(id)}
            setSelected={props.setSelected}
          />
        );
        //setObjects((prevState) => [newImage, ...prevState]);
        console.log(props.setObjects);
        console.log(props.objects);
        setObjects([...props.objects, newImage]);
      }
    }
  };

  // const onClickBackButton = (event) => {
  //   props.close();
  // };

  const onLoadImageOfAddress = (event) => {
    const data = event.detail.data;

    let addressString = data;
    let dataMapType = mapType;
    let dataMapHeight = mapHeight;
    let dataMapWidth = mapWidth;
    let dataMapZoom = zoomLevel;

    if (typeof data === 'object') {
      addressString = data.address;

      if (data.mapType) {
        dataMapType = data.mapType;
      }
      if (data.mapHeight) {
        dataMapHeight = data.mapHeight;
      }
      if (data.mapWidth) {
        dataMapWidth = data.mapWidth;
      }
      if (data.mapZoom) {
        dataMapZoom = data.mapZoom;
      }
    }

    dataMapType = dataMapType.toLowerCase();
    const id = props.getRandomId();
    const url = `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
      addressString
    )}&zoom=${dataMapZoom}&size=${dataMapHeight}x${dataMapWidth}&maptype=${dataMapType}&scale=2&key=AIzaSyDP-qIDSVJsPzvIitvxMKFugslOckBlg0o`;
    setObjects(() => [
      <DraggableImage
        key={id}
        url={url}
        id={id}
        setSaveData={props.setSaveData}
        saveData={props.saveData}
        mapType={dataMapType}
        zoomLevel={dataMapZoom}
        mapHeight={dataMapHeight}
        mapWidth={dataMapWidth}
        objects={props.objects}
        setObjects={setObjects}
        delete={() => props.deleteObject(id)}
        setSelected={props.setSelected}
        grayscaleFilter={true}
      />,
      ...props.objects
    ]);
  };

  const onLoadUploadedImage = (event) => {
    const url = event.detail.data;
    const id = props.getRandomId();
    setObjects(() => [
      <DraggableImage
        key={id}
        url={url}
        id={id}
        saveData={props.saveData}
        setSaveData={props.setSaveData}
        objects={props.objects}
        setObjects={setObjects}
        delete={() => props.deleteObject(id)}
        setSelected={props.setSelected}
      />,
      ...props.objects
    ]);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => changeTool('pan')}>
        {PanToolButton}
        <p>Pan Tool</p>
      </MenuItem>
      <MenuItem>
        {ImageButton}
        <p>Add Image</p>
      </MenuItem>
      <MenuItem onClick={(e) => changeTool('fence')}>
        {FenceToolButtonMobile}
        <p>Fence Tool</p>
      </MenuItem>
      <MenuItem onClick={(e) => changeTool('tearout')}>
        {TearOutFenceToolButtonMobile}
        <p>Tear-Out Fence Tool</p>
      </MenuItem>
      <MenuItem onClick={(e) => changeTool('existing')}>
        {ExistingFenceToolButtonMobile}
        <p>Existing Fence Tool</p>
      </MenuItem>
      <MenuItem onClick={() => changeTool('gate')}>
        {GateToolButtonMobile}
        <p>Gate Tool</p>
      </MenuItem>
      <MenuItem onClick={() => changeTool('double-gate')}>
        {DoubleGateToolButtonMobile}
        <p>Double Gate Tool</p>
      </MenuItem>
      <MenuItem onClick={() => changeTool('arrow')}>
        {ArrowToolButton}
        <p>Arrow Tool</p>
      </MenuItem>
      <MenuItem onClick={() => changeTool('rectangle')}>
        {RectangleToolButton}
        <p>Rectangle Tool</p>
      </MenuItem>
      <MenuItem onClick={() => changeTool('circle')}>
        {CircleToolButton}
        <p>Circle Tool</p>
      </MenuItem>
      <MenuItem onClick={() => changeTool('text')}>
        {TextToolButton}
        <p>Text Tool</p>
      </MenuItem>
    </Menu>
  );

  //if (window.location.pathname === '/') return null;

  return (
    <>
      <Box sx={{ flexGrow: 1, backgroundColor: theme.palette.surfaces.background }}>
        <AppBar
          position="fixed"
          color={'action'}
          className={`z-[999] shadow-sm w-full`}
        >
          <Toolbar sx={{ backgroundColor: theme.palette.surfaces.background }}>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              sx={{ mr: 2 }}
              onClick={onClickBackButton}
            >
              <ArrowBackIosNewIcon />
            </IconButton> */}
            {organization.loading && !organization ? (
              <CircularProgress
                color="inherit"
                size="20px"
                className="justify-self-center"
              />
            ) : (
              <img
                onClick={() => navigate('/')}
                className="align-middle max-w-[150px] max-h-[50px] cursor-pointer h-full p-[5px]"
                src={organization.logo ? organization.logo : require('../../../assets/Logo Colored 3.png')}
              />
            )}

            <Box sx={{ flexGrow: 1 }} />
            {auth.user && !window.location.pathname.includes('settings') && !window.location.pathname.includes('history') ? (
              <ContactSearchBar loadSatelitteImage={handleConfirm} />
            ) : (
              <Box sx={{ flexGrow: 1 }} />
            )}
            {window.location.pathname === '/' ? (
              <>
                <Box
                  sx={{ display: { xs: 'none', sm: 'flex' } }}
                  className="w-full place-content-end"
                >
                  {PanToolButton}
                  {ImageButton}
                  {FenceToolButton}
                  {GateToolButton}
                  {ArrowToolButton}
                  {RectangleToolButton}
                  {SymbolToolButton}

                  {TextToolButton}
                </Box>
                <Box sx={{ display: 'flex' }}>{SaveButton}</Box>
              </>
            ) : null}

            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              {window.location.pathname !== '/' ? null : (
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              )}
            </Box>
            <DropdownMenu />
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </Box>
      <Dialog open={openDialog}>
        <DialogTitle>Address Lookup</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter an address to lookup via Google Maps. This will capture a satellite view image of the address and add it to the draw space.
          </DialogContentText>
          <Box>
            <Grid
              sx={{
                flexGrow: 1,
                justifyContent: 'flex-start'
              }}
              container
              spacing={2}
            >
              {/*address*/}
              <Grid
                item
                xs={12}
              >
                <TextField
                  //autoFocus
                  margin="dense"
                  id="name"
                  label="Address"
                  fullWidth
                  variant="standard"
                  onChange={onAddressChange}
                  value={address ? address : ''}
                />
              </Grid>

              {/*Map Type*/}
              <Grid
                item
                xs={6}
              >
                <TextField
                  id="maptype"
                  variant="standard"
                  select
                  label="Map Type"
                  value={mapType}
                  onChange={onMapTypeChange}
                  fullWidth
                  margin="dense"
                >
                  <MenuItem
                    key="roadmap"
                    value="roadmap"
                  >
                    Roadmap
                  </MenuItem>
                  <MenuItem
                    key="satellite"
                    value="satellite"
                  >
                    Satellite
                  </MenuItem>
                  <MenuItem
                    key="hybrid"
                    value="hybrid"
                  >
                    Hybrid
                  </MenuItem>
                  <MenuItem
                    key="terrain"
                    value="terrain"
                  >
                    Terrain
                  </MenuItem>
                </TextField>
              </Grid>

              {/*Map Height*/}
              <Grid
                item
                xs={3}
              >
                <TextField
                  variant="standard"
                  label="Map Height"
                  value={mapHeight}
                  onChange={onMapHeightChange}
                  margin="dense"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                />
              </Grid>

              {/*Map Width*/}
              <Grid
                item
                xs={3}
              >
                <TextField
                  variant="standard"
                  label="Map Width"
                  value={mapWidth}
                  onChange={onMapWidthChange}
                  margin="dense"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                  }}
                />
              </Grid>

              {/*Zoom*/}
              <Grid
                item
                xs={12}
              >
                <FormControl
                  fullWidth
                  margin="dense"
                >
                  <InputLabel
                    id="zoomSliderLabel"
                    htmlFor="zoomSlider"
                    focused={false}
                    shrink={true}
                    variant="standard"
                  >
                    Zoom
                  </InputLabel>
                  <Slider
                    id="zoomSlider"
                    label="Zoom"
                    aria-labelledby="zoomSliderLabel"
                    defaultValue={zoomLevel}
                    value={zoomLevel}
                    valueLabelDisplay="on"
                    step={1}
                    marks
                    min={16}
                    max={22}
                    onChange={onMapZoomChange}
                    sx={{
                      marginTop: '16px'
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <label htmlFor="contained-button-file">
            <Button component="span">Upload</Button>
          </label>
          <HiddenInput
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={onFileUpload}
          />
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={showClearConfirmModal}
        onClose={() => setShowClearConfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to clear?
          </Typography>
          <div className="flex space-x-5 mt-6">
            <Button
              variant="contained"
              onClick={handleClear}
            >
              Yes
            </Button>
            <Button variant="outlined">Cancel</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
