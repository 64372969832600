import { useRef, useEffect, useState } from 'react';
import Konva from 'konva';
import { Group, Image, Transformer } from 'react-konva';
import useImage from 'use-image';
import { Html } from 'react-konva-utils';
import { styled } from '@mui/material/styles';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import { isMobile } from 'react-device-detect';

export default function DraggableImage(props) {
  const [selected, setSelected] = useState(false);
  const [isDraggingAllowed, setIsDraggingAllowed] = useState(false);

  const [imageFromURL, imageFromURLStatus] = useImage(props.url); // Get image and its loading status

  const imageFromFile = props.image;
  const image = props.url ? imageFromURL : imageFromFile;
  const menuRef = useRef(null);
  const imageRef = useRef(null);
  const transformerRef = useRef(null);

  useEffect(() => {
    console.log('render');
    document.addEventListener('draggingAllowed', onDraggingAllowed);

    return () => {
      document.removeEventListener('draggingAllowed', onDraggingAllowed);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('clearSelections', onClearSelections);

    return () => {
      document.removeEventListener('clearSelections', onClearSelections);
    };
  }, []);

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    imageRef.current.cache();
    //imageRef.current.filters([Konva.Filters.Grayscale]);
    console.log(props.grayscaleFilter);
    if (props.grayscaleFilter === true) {
      imageRef.current.filters([Konva.Filters.Grayscale]);
    }
  }, [image]);

  const onDraggingAllowed = (e) => {
    if (e.detail.state) {
      setIsDraggingAllowed(true);
    } else {
      setIsDraggingAllowed(false);
    }
  };

  // const onDragMove = (e) => {
  //   setX(imageRef.current.attrs.x);
  //   setY(imageRef.current.attrs.y);
  // };

  const onDragEnd = (e) => {
    // setX(imageRef.current.attrs.x);
    // setY(imageRef.current.attrs.y);
    save();
  };

  const onTap = (e) => {
    if (e.evt.touches.length > 1) {
      //onContextMenu(e);
    } else {
      onSelect(e);
    }
  };

  const save = () => {
    console.log(imageRef.current.attrs);
    if (props.setSaveData) {
      props.setSaveData({
        [props.id]: {
          id: props.id,
          key: props.id,
          type: 'DraggableImage',
          url: props.url,
          width: imageRef.current.attrs.width,
          height: imageRef.current.attrs.height,
          scaleX: imageRef.current.attrs.scaleX || 1,
          scaleY: imageRef.current.attrs.scaleY || 1,
          rotation: imageRef.current.attrs.rotation,
          x: imageRef.current.attrs.x,
          y: imageRef.current.attrs.y
        }
      });
    }
  };

  const onClearSelections = (e) => {
    const target = e.detail.target;
    const hasParent = Boolean(target.parent);
    let hasNodes = false;
    if (hasParent) {
      hasNodes = Boolean(target.parent._nodes);
    }

    if (target === imageRef.current) {
      // setSelected(true);
      // setShowTransformer(true);
      // props.setSelected(circleRef);
      //skip
    } else if (hasParent && hasNodes && target.parent._nodes[0] === imageRef.current) {
      //skip
    } else {
      setSelected(false);
    }
  };

  const onSelect = (e) => {
    setSelected(true);
    props.setSelected(imageRef);
  };

  const onTransformEnd = (e) => {
    save();
  };

  return (
    <Group
      key={props.id}
      id={props.id}
    >
      <Image
        {...props}
        key={props.id}
        onClick={onSelect}
        image={image}
        id={props.id}
        draggable={selected}
        onTouchStart={onTap}
        ref={imageRef}
        //onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onTransformEnd={onTransformEnd}
        x={props.x}
        y={props.y}
        // height={props.height || 'auto'}
        // width={props.width}
        rotation={props.rotation}
      />
      {selected && (
        <Transformer
          ref={transformerRef}
          nodes={[imageRef.current]}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Group>
  );
}
