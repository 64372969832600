import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getContacts as getContactsFromJobNimbus } from '../services/jobnimbus';
import { getContactsFromJobber, refreshAccessToken } from '../api/jobber';
import { CircularProgress, Autocomplete, TextField } from '@mui/material';

function ContactSearchBar(props) {
  //const [user, loading, error] = useAuthState(authStatus);
  const auth = useSelector((state) => state.auth);
  const contact = useSelector((state) => state.sketch.contact);
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [open, setOpen] = React.useState(false);
  const loading = open && contacts.length === 0;

  // useEffect(async () => {
  // //console.log(sketch);
  // if (!auth.user) return;
  // let userData = auth.user;

  // let dataSource = Object.keys(userData).filter((e, index) => {
  //   if (e.includes('Integration') && userData[e].enabled) {
  //     //integrationsEnabled.push(userData[e]);
  //     return e;
  //   }
  // });

  // switch (dataSource[0]) {
  //   case 'jobberIntegration':
  //     let access_token = userData[dataSource].access_token;
  //     let refresh_token = userData[dataSource].refresh_token;
  //     console.log('users data source is jobber');
  //     const response = await getContactsFromJobber(access_token);

  //     console.log(response);
  //     setContacts(
  //       response.map((e) => {
  //         if (!e.billingAddress) return;
  //         return {
  //           ...e,
  //           id: e.id,
  //           display_name: e.firstName + ' ' + e.lastName,
  //           address: `${e.billingAddress.street} ${e.billingAddress.city}, ${e.billingAddress.province} ${e.billingAddress.postalCode}`
  //         };
  //       })
  //     );
  //     break;
  //   case 'jobNimbusIntegration':
  //     let apiKey = userData[dataSource].apiKey;

  //     await getContactsFromJobNimbus(apiKey).then((data) =>
  //       setContacts(data.results.map((e) => ({ ...e, id: e.jnid, address: `${e.address_line1} ${e.city}, ${e.state_text} ${e.zip}` })))
  //     );
  //     break;
  //   default:
  //     break;
  // }
  // }, []);

  // useEffect(() => {
  //   if (contact !== null) {
  //     let searchInput = document.querySelectorAll('[data-test="search-input"]');
  //     console.log(searchInput);
  //     searchInput[0].value = contact.display_name;
  //   }
  // }, [contact]);

  const handleOnSelect = (item) => {
    if (!item) return;
    // the item selected
    // dispatch(setContact(item));
    dispatch({ type: 'SET_CONTACT', data: item });
    console.log(item);
    let contactAddress = item.address;
    console.log(contactAddress);
    //props.loadSatelitteImage(contactAddress);
    let input = document.getElementById('contact-search-bar').querySelector('input');

    input.blur();
  };

  const handleOnFocus = async () => {
    if (contacts.length === 0) {
      await handleFetchContacts();
    }
    console.log('Focused');
  };

  const handleFetchContacts = async () => {
    if (!auth.user) return;
    let userData = auth.user;

    let dataSource = Object.keys(userData).filter((e, index) => {
      if (e.includes('Integration') && userData[e].enabled) {
        return e;
      }
    });

    switch (dataSource[0]) {
      case 'jobberIntegration':
        let access_token = userData[dataSource].access_token;
        let refresh_token = userData[dataSource].refresh_token;
        console.log('users data source is jobber');
        const response = await getContactsFromJobber(access_token);

        console.log(response);
        setContacts(
          response.map((e) => {
            if (!e.billingAddress) return;
            return {
              ...e,
              id: e.id,
              display_name: e.firstName + ' ' + e.lastName,
              address: `${e.billingAddress.street} ${e.billingAddress.city}, ${e.billingAddress.province} ${e.billingAddress.postalCode}`
            };
          })
        );
        break;
      case 'jobNimbusIntegration':
        let apiKey = userData[dataSource].apiKey;

        const response2 = await getContactsFromJobNimbus(apiKey).then(
          (data) => data.results.map((e) => ({ ...e, id: e.jnid, address: `${e.address_line1} ${e.city}, ${e.state_text} ${e.zip}`, label: e.display_name }))

          //setContacts(data.results.map((e) => ({ ...e, id: e.jnid, address: `${e.address_line1} ${e.city}, ${e.state_text} ${e.zip}` })))
        );
        setContacts(response2);

        break;
      default:
        break;
    }
  };

  return (
    <div
      id="contact-search-bar"
      className={`${userHasIntegrationEnabled(auth.user) ? 'w-full mx-5 z-50 max-w-[300px] min-w-[48px]' : '!invisible !h-0 !p-0'}`}
    >
      <Autocomplete
        onFocus={handleOnFocus}
        value={searchText}
        onChange={(event, newValue) => {
          handleOnSelect(newValue);
        }}
        inputValue={searchText}
        onInputChange={(event, newInputValue) => {
          console.log(typeof newInputValue);
          if (typeof newInputValue === 'string') setSearchText(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => {
          //console.log(option, value);
          return option.display_name === value;
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        disablePortal
        id="contact-search-box"
        options={contacts}
        size="small"
        // sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for contact"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </div>
  );
}

export default ContactSearchBar;

function userHasIntegrationEnabled(userData) {
  let integrationsEnabled = [];

  Object.keys(userData).forEach((e, index) => {
    if (e.includes('Integration') && userData[e].enabled) {
      integrationsEnabled.push(userData[e]);
    }
  });

  if (integrationsEnabled.length > 0) {
    return true;
  } else return false;
}
