import { useRef, useEffect, useState } from 'react';
import { Rect, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import { styled } from '@mui/material/styles';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';

//import { useSketchContext, setObjects } from '../../../contexts/SketchContext';

const ContextMenu = styled('div')`
  display: none;
  position: absolute;
  width: 220px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
`;

const ContextMenuButton = styled('button')`
  width: 100%;
  background-color: white;
  border: none;
  margin: 0;
  padding: 10px;
  :hover {
    background-color: lightgray;
  }
`;

export default function RectWithTransformer(props) {
  const [showTransformer, setShowTransformer] = useState(false);
  const [selected, setSelected] = useState(false);

  const rectRef = useRef(null);
  const transformerRef = useRef(null);

  //const { sketch, dispatch } = useSketchContext();

  useEffect(() => {
    document.addEventListener('clearSelections', onClearSelections);

    return () => {
      document.removeEventListener('clearSelections', onClearSelections);
    };
  }, []);

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    if (showTransformer) {
      transformerRef.current.nodes([rectRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [showTransformer]);

  const onClearSelections = (e) => {
    const target = e.detail.target;
    const hasParent = Boolean(target.parent);
    let hasNodes = false;
    if (hasParent) {
      hasNodes = Boolean(target.parent._nodes);
    }

    if (target === rectRef.current) {
      // setSelected(true);
      // setShowTransformer(true);
      // props.setSelected(circleRef);
      //skip
    } else if (hasParent && hasNodes && target.parent._nodes[0] === rectRef.current) {
      //skip
    } else {
      setSelected(false);
      setShowTransformer(false);
    }
  };

  const onSelect = (e) => {
    if (e.evt.button === 0 || e.evt.touches) {
      setShowTransformer(true);
      setSelected(true);
      props.setSelected(rectRef);
    }
  };

  const onTap = (e) => {
    if (e.evt.touches.length > 1) {
      //onContextMenu(e);
    } else {
      onSelect(e);
    }
  };

  const onDragEnd = (e) => {
    save();
  };

  const onTransformEnd = (e) => {
    save();
  };

  const save = () => {
    //console.log(rectRef.current.attrs);
    if (props.setSaveData) {
      return props.setSaveData({
        [props.id]: {
          id: props.id,
          key: props.id,
          type: 'RectWithTransformer',
          x: rectRef.current.attrs.x,
          y: rectRef.current.attrs.y,
          width: rectRef.current.attrs.width,
          height: rectRef.current.attrs.height,
          scaleX: rectRef.current.attrs.scaleX || 1,
          scaleY: rectRef.current.attrs.scaleY || 1,
          rotation: rectRef.current.attrs.rotation
        }
      });
    }
  };

  return (
    <>
      <Rect
        {...props}
        onClick={onSelect}
        ref={rectRef}
        draggable={showTransformer}
        strokeScaleEnabled={false}
        //onContextMenu={onContextMenu}
        onTouchStart={onTap}
        onDragEnd={onDragEnd}
        onTransformEnd={onTransformEnd}
        fill={'#A99C8F'}
        opacity={0.5}
        stroke={'#FF7F3F'}
        strokeWidth={5}
        // shadowColor={'#ED5521'}
        // shadowBlur={1}
        strokeEnabled={true}
        //shadowEnabled={selected}
        //perfectDrawEnabled={false}
        //opacity={0.75}
      />
      {showTransformer && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
      {/* <Html>
        <ContextMenu
          id="menu"
          ref={menuRef}
        >
          {selected && (
            <>
              <ContextMenuButton onClick={onClickDuplicate}>Duplicate</ContextMenuButton>
              <ContextMenuButton onClick={onClickDelete}>Delete</ContextMenuButton>
            </>
          )}
        </ContextMenu>
      </Html> */}
    </>
  );
}
