import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { logout } from '../../api/auth';
import { notify } from '../../utils/notification';

export default function Logout() {
  const navigate = useNavigate();
  useEffect(async () => {
    await logout();
    navigate('/login');
  }, []);

  return <div>Logging out</div>;
}
