import { useRef, useEffect, useState } from 'react';
import { Text } from 'react-konva';
import { Html } from 'react-konva-utils';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DraggableText(props) {
  const [isDraggingAllowed, setIsDraggingAllowed] = useState(false);
  const [text, setText] = useState(props.text);

  const [openTextDialog, setOpenTextDialog] = useState(text === '');
  const [selected, setSelected] = useState(false);
  const stageRef = props.stageRef;
  const textRef = useRef(null);

  useEffect(() => {
    document.addEventListener('draggingAllowed', onDraggingAllowed);

    return () => {
      document.removeEventListener('draggingAllowed', onDraggingAllowed);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('clearSelections', onClearSelections);

    return () => {
      document.removeEventListener('clearSelections', onClearSelections);
    };
  }, []);

  // useEffect(() => {
  //   let inputElement = document.getElementById('text');
  //   if (inputElement) inputElement.focus();
  // }, []);

  useEffect(() => {
    save();
  }, []);

  const onTextChange = (e) => {
    setText(e.target.value);
    save();
  };
  const handleDeleteText = () => {
    const shapeId = textRef.current.id();
    props.delete(props.id);
    setOpenTextDialog(false);
    props.setSaveData((prevState) => {
      let newState = { ...prevState };
      delete newState[shapeId];
      return newState;
    });
    textRef.current.destroy();
  };
  const handleConfirmText = () => {
    if (textRef.current.attrs.text === '') handleDeleteText();
    setOpenTextDialog(false);
  };

  const onDraggingAllowed = (e) => {
    if (e.detail.state) {
      setIsDraggingAllowed(true);
    } else {
      setIsDraggingAllowed(false);
    }
  };

  const onContextMenu = (e) => {
    e.evt.preventDefault();
    setOpenTextDialog(true);
    setSelected(true);
    props.setSelected(textRef);
    //inputRef.current.focus();
  };

  const onSelect = (e) => {
    if (e.evt.button === 0 || e.evt.touches) {
      setSelected(true);
      props.setSelected(textRef);
    }
  };

  const onDragEnd = (e) => {
    save();
  };

  const save = () => {
    if (props.setSaveData) {
      props.setSaveData({
        [props.id]: {
          id: props.id,
          key: props.id,
          type: 'DraggableText',
          x: textRef.current.attrs.x,
          y: textRef.current.attrs.y,
          text: textRef.current.attrs.text
        }
      });
    }
  };

  const onClearSelections = (e) => {
    const emptySpace = e.detail.target === e.detail.target.getStage();
    if (emptySpace) {
      setSelected(false);
      props.setSelected(null);
    } else if (e.detail.target === textRef) {
      setSelected(true);
      props.setSelected(textRef);
    }
  };

  return (
    <>
      <Text
        key={props.id}
        id={props.id}
        text={text}
        x={props.x}
        y={props.y}
        fontSize={22}
        fontStyle={'bold'}
        fill={'#151515'}
        draggable={true}
        onContextMenu={onContextMenu}
        onDragEnd={onDragEnd}
        onTap={onContextMenu}
        onClick={onSelect}
        ref={textRef}
        stroke={'#ED5521'}
        strokeWidth={0.1}
        shadowColor={'#4D4D22'}
        shadowBlur={0.1}
        strokeEnabled={selected}
        shadowEnabled={selected}
        transformsEnabled="position"
      />
      <Html>
        <Dialog
          open={openTextDialog}
          maxWidth={'md'}
          fullWidth
        >
          <DialogTitle>Enter Text</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus={true}
              margin="dense"
              id="text"
              label="Text"
              fullWidth
              variant="standard"
              value={text}
              onChange={onTextChange}
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={handleDeleteText}
            >
              Delete
            </Button>
            <Button onClick={handleConfirmText}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </Html>
    </>
  );
}
