import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthContext, setUser } from '../../../contexts/AuthContext';
import { saveUserJobNimbusIntegrationSettings } from '../../../api/user';
import Typography from '@mui/material/Typography';

export default function JobNimbusSettings() {
  const auth = useSelector((state) => state.auth);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (!auth.user) return;

    setSettings(auth.user.jobNimbusIntegration);
  }, [auth.user]);

  const onBlurSaveApiKey = async (e) => {
    setSettings({ ...settings, apiKey: e.target.value, enabled: true });
    const { error, response } = await saveUserJobNimbusIntegrationSettings(auth.user.ref, { ...settings, apiKey: e.target.value, enabled: true });
    if (error) setSettings({ ...settings, enabled: false });
  };

  const onSaveToggle = async (e) => {
    setSettings({ ...settings, enabled: !settings.enabled });
    const { error, response } = await saveUserJobNimbusIntegrationSettings(auth.user.ref, { ...settings, enabled: !settings.enabled });
    if (error) setSettings({ ...settings, enabled: false });
  };

  return (
    <div className="justify-center px-10 py-5">
      <Typography variant="h1">JobNimbus settings</Typography>
      <div className="mb-3 w-full">
        <p className="text-xs my-2 text-onPrimaryBgSofter">Enable JobNimbus Integration</p>
        <label
          for="enableJobNimbusToggle"
          className="flex relative items-center mb-4 cursor-pointer max-w-fit"
        >
          <input
            type="checkbox"
            id="enableJobNimbusToggle"
            className="sr-only"
            checked={settings?.enabled !== undefined ? settings?.enabled : false}
            onClick={onSaveToggle}
          />
          <div className="w-11 h-6 bg-onPrimaryBgSofter rounded-full border border-onPrimaryBgSofter toggle-bg"></div>
          <span className="ml-3 font-medium text-onPrimaryBgSofter text-xs">{settings && settings.enabled ? settings.enabled.toString() : ''}</span>
        </label>
        <p className="text-xs my-2 text-onPrimaryBgSofter">Set API Key</p>
        <input
          id="apiKeyInput"
          defaultValue={settings && settings.apiKey}
          onBlur={onBlurSaveApiKey}
          type="text"
          className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700
         focus:bg-white 
         focus:border-header 
         focus:outline-header 
         focus:shadow-none
      "
          placeholder="API Key"
        />
      </div>
    </div>
  );
}
