import { updateDoc, arrayUnion, getDoc, doc, collection, addDoc, deleteDoc, getDocs, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import { toast } from 'react-toastify';

const { notify } = require('../utils/notification');

export const updateDrawing = async (organizationRef, drawingId, data) => {
  console.log(data);

  try {
    let newData = data;

    const drawingDocRef = doc(organizationRef, 'drawings', drawingId);
    await updateDoc(drawingDocRef, newData);

    toast.success(`Successfully updated drawing: ${drawingId}`);
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};

export const addNewDrawing = async (organizationRef, drawingData) => {
  console.log(organizationRef);
  try {
    const drawingCollectionRef = collection(organizationRef, 'drawings');
    const newDrawingDoc = await addDoc(drawingCollectionRef, drawingData);

    // // Update the user document with the new drawing reference using arrayUnion
    // await updateDoc(userDocReference, {
    //   drawings: arrayUnion(drawingsCollectionRef)
    // });
    console.log(newDrawingDoc.id);
    toast.success(`New drawing successfully saved: ${drawingData.projectName}`);
    return newDrawingDoc.id;
  } catch (error) {
    console.log(error.message);
    toast.error(error.message);
  }
};

export const deleteDrawing = async (organizationRef, drawingId) => {
  try {
    const drawingDocRef = doc(organizationRef, 'drawings', drawingId);
    await deleteDoc(drawingDocRef);

    toast.success(`Successfully deleted drawing: ${drawingId}`);
  } catch (error) {
    console.error('Error deleting quote:', error);
    toast.error(error.message);
  }
};

export const getOrganizationDrawings = async (organizationDocReference) => {
  try {
    const drawingsCollectionRef = collection(organizationDocReference, 'drawings');
    const drawingsSnapshot = await getDocs(drawingsCollectionRef);
    const drawingsData = drawingsSnapshot.docs.map((doc) => doc.data());
    console.log(drawingsData);
    return drawingsData;
  } catch (error) {
    console.error('Error getting organization drawings:', error);
    return [];
  }
};

// export const getDrawings = async (userDocReference, data) => {
//   await updateDoc(userDocReference, data);
//   return console.log('user drawing added');
// };
