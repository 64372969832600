import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaletteIcon from '@mui/icons-material/Palette';
import GroupIcon from '@mui/icons-material/Group';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SettingsSidebar() {
  let location = useLocation();
  const navigate = useNavigate();
  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (organization.loading) return;
    setLoading(false);
  }, [organization.loading]);

  //if (organization?.owner !== user?.email) return null;
  return (
    <aside
      className="w-52 h-screen fixed overflow-y-auto"
      aria-label="Sidebar"
    >
      <div className="h-screen overflow-y-auto py-4 px-3 ">
        <MenuList>
          {/* <MenuItem
            onClick={() => navigate('/settings/billing')}
            selected={location.pathname.includes('billing')}
            className={`${organization?.owner !== user?.email ? '!invisible !h-0 !p-0' : ''}`}
          >
            <ListItemIcon>
              <CreditCardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Billing</ListItemText>
          </MenuItem> */}
          <MenuItem
            onClick={() => navigate('/settings/branding')}
            selected={location.pathname.includes('branding')}
            className={`${organization?.owner !== user?.email ? '!invisible !h-0 !p-0' : ''}`}
            //className="invisible"
          >
            <ListItemIcon>
              <PaletteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Branding</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => navigate('/settings/team')}
            selected={location.pathname.includes('team')}
          >
            <ListItemIcon>
              <GroupIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Team</ListItemText>
          </MenuItem>
          {/* <div className="divide-solid bg-primaryText/25 h-[1px] m-3"></div> */}
          {/* <MenuItem
            onClick={() => navigate('/settings/jobnimbus')}
            selected={location.pathname.includes('jobnimbus')}
          >
            <ListItemIcon>
              <img
                className="w-[20px] h-[20px] text-gray-500 transition duration-75"
                src="https://dka575ofm4ao0.cloudfront.net/pages-transactional_logos/retina/196734/JN_Logo_Full-Blue.png"
              />
            </ListItemIcon>
            <ListItemText>Jobnimbus</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => navigate('/settings/jobber')}
            selected={location.pathname.includes('jobber')}
          >
            <ListItemIcon>
              <img
                className="w-[20px] h-[20px] text-gray-500 transition duration-75"
                src="https://getjobber.com/wp-content/uploads/2019/11/jobber_logo.png"
              />
            </ListItemIcon>
            <ListItemText>Jobber</ListItemText>
          </MenuItem> */}
          <div className="divide-solid bg-primaryText/25 h-[1px] m-3"></div>
          <MenuItem
            onClick={() => navigate('/settings/support')}
            selected={location.pathname.includes('support')}
          >
            <ListItemIcon>
              <HelpCenterIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Support</ListItemText>
          </MenuItem>
        </MenuList>
      </div>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: 99999999 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </aside>
  );
}
