import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { query, getDocs, doc, addDoc, deleteDoc, collection, where } from 'firebase/firestore';
import { db } from '../../../api/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

export default function Team() {
  const auth = useSelector((state) => state.auth);
  const organization = useSelector((state) => state.organization);
  const [invites, setInvites] = useState([]);
  const [email, setEmail] = useState('');
  const theme = useTheme();

  useEffect(async () => {
    if (!auth.user) return;

    const querySnapshot = await getDocs(query(collection(db, 'invites'), where('sender', '==', auth.user.uid)));
    setInvites(querySnapshot.docs);
  }, [auth.user]);

  const sendInvite = async () => {
    const docRef = await addDoc(collection(db, 'invites'), {
      email: email,
      sender: auth.user.uid,
      organizationId: auth.user.organization,
      senderName: auth.user.name,
      status: 'pending'
    });

    setInvites([...invites, docRef]);
  };

  const handleRevoke = async (inviteRef) => {
    await deleteDoc(doc(db, 'invites', inviteRef.id));
    setInvites(invites.filter((invite) => invite.id !== inviteRef.id));
  };
  console.log(organization.members);
  return (
    <div className="justify-center px-10 py-5">
      <Typography variant="h1">Team</Typography>
      {/* <Typography
        className="mb-5"
        fontSize={'16px'}
      >
        Invite your team members to use Fence Sketch.
      </Typography> */}
      <br></br>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  {/* Add other relevant columns for team members */}
                </TableRow>
              </TableHead>
              <TableBody>
                {organization?.members?.map((member) => (
                  <TableRow
                    key={member?.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: '12px' }}>{member?.name}</TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>{member?.email}</TableCell>
                    {/* Add other relevant cells for team members */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <div className="divide-solid bg-primaryText/25 h-[1px] my-3"></div>
      {/* <Grid
        container
        spacing={2}
      >
        <Grid item>
          <Typography fontSize={'16px'}>Pending Invites</Typography>
          <Typography
            variant="h3"
            marginTop={1}
            fontSize={'12px'}
          >
            Team members who you've sent an invite to and have not accepted it yet.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invites.map((invite) => (
                  <TableRow
                    key={invite.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: '12px' }}>{invite.data()?.email}</TableCell>
                    <TableCell sx={{ fontSize: '12px' }}>{invite.data()?.status}</TableCell>
                    <TableCell xs={8}>
                      <div className="flex justify-evenly">
                        <Button
                          variant="contained"
                          sx={{ fontSize: '10px', marginX: 0.5 }}
                        >
                          Resend
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => handleRevoke(invite)}
                          sx={{
                            fontSize: '10px',
                            marginX: 0.5,
                            border: 1,
                            borderColor: theme.palette.status.critical.default,
                            color: theme.palette.status.critical.default,
                            backgroundColor: 'transparent',
                            '&:hover': {
                              backgroundColor: theme.palette.status.critical.surface,
                              borderColor: theme.palette.status.critical.default
                            }
                          }}
                        >
                          Revoke
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid> */}
    </div>
  );
}
