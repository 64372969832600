import React, { useEffect } from 'react';
import { auth } from '../api/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function DropdownMenu(props) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-gray-900">
          <img
            type="button"
            id="dropdownMenuButton1"
            data-te-dropdown-toggle-ref
            aria-expanded="false"
            src={user && user.photoURL}
            className=" max-w-[40px]  inline cursor-pointer rounded-full select-none"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[999]">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => navigate('/history')}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
                    'block px-4 py-2 text-sm cursor-pointer font-sans font-medium text-md'
                  )}
                >
                  History
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => navigate('/settings/')}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
                    'block px-4 py-2 text-sm cursor-pointer font-sans font-medium text-md'
                  )}
                >
                  Settings
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  onClick={() => navigate('/settings/support')}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
                    'block px-4 py-2 text-sm cursor-pointer font-sans font-medium text-md'
                  )}
                >
                  Support
                </a>
              )}
            </Menu.Item>
            <form
              method="POST"
              action="#"
            >
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => navigate('/logout')}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
                      'block px-4 py-2 text-sm cursor-pointer font-sans font-medium text-md'
                    )}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
