import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAuthContext } from '../../../contexts/AuthContext';
import { query, collection, where, getDocs, doc, getDoc, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../api/firebase';
import { createCheckoutSession, createPortalLink } from '../../../api/stripe';

import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

export default function Billing() {
  const auth = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(async () => {
    await getDocs(query(collection(db, 'products'), where('name', '==', 'Fence Sketch Pro'))).then((querySnapshot) => {
      //const products = [];
      querySnapshot.forEach(async (productDoc) => {
        let product = { id: productDoc.id, ...productDoc.data() };

        const priceSnap = await getDocs(collection(productDoc.ref, 'prices'));

        priceSnap.docs.forEach((price) => {
          product.prices = {
            priceId: price.id,
            priceData: price.data()
          };
        });
        console.log(product);

        setProducts([...products, product]);
      });
    });
    setLoading(false);
  }, [auth.user]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      //border: `2px solid ${theme.palette.background.paper}`,
      padding: '0',
      backgroundColor: theme.palette.brand.brand,
      color: theme.palette.surfaces.background,
      visibility: auth.user.stripeRole === 'premium' ? 'visible' : 'hidden'
    }
  }));

  return (
    <div className="justify-center px-10 py-5">
      <Typography variant="h1">Billing</Typography>
      <div className="mb-3 w-full ">
        <Typography
          mb={1}
          fontSize={'16px'}
        >
          Your plan:{' '}
          {!auth.user ? null : auth.user?.stripeRole ? (
            <>
              <span className="font-bold capitalize">&nbsp;{auth.user?.stripeRole}</span> <br />
              <Button
                sx={{ marginTop: 1 }}
                variant="contained"
                endIcon={<ArrowOutwardIcon />}
                onClick={() => createPortalLink().then(() => setLoading(true))}
                //onClick={() => (window.location = 'https://buy.stripe.com/test_aEU8yx5Lh6pDgJa6op')}
              >
                Manage Plan
              </Button>
            </>
          ) : (
            <>
              <span className="font-bold">&nbsp;Free</span> <br />
              <Button
                sx={{ marginTop: 1 }}
                variant="contained"
                endIcon={<ArrowOutwardIcon />}
                onClick={() => createCheckoutSession(auth.user.email, products[0].prices.priceId).then(() => setLoading(true))}
                //onClick={() => (window.location = 'https://buy.stripe.com/test_aEU8yx5Lh6pDgJa6op')}
              >
                Upgrade Plan
              </Button>
            </>
          )}
        </Typography>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

  return (
    <div className="justify-center px-10 py-5">
      <Typography variant="h1">Billing</Typography>

      <div className="mb-3 w-full">
        <Typography
          variant="h2"
          mb={1}
        >
          Plans
        </Typography>

        {Object.entries(products).map(([productId, productData], index) => {
          console.log(productData);
          return (
            <StyledBadge
              badgeContent={<CheckIcon className="w-3" />}
              key={index}
            >
              <Card
                sx={{
                  maxWidth: 345,
                  backgroundColor: theme.palette.surfaces.background,
                  border: 2,
                  borderColor: auth.user.stripeRole === 'premium' ? theme.palette.brand.brand : theme.palette.overlay.default
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="font-sans text-sm font-bold text-onPrimaryBgSofter"
                  >
                    {productData.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  >
                    {productData.description}
                  </Typography>
                </CardContent>
                <CardActions className="ml-0 pl-4">
                  {auth.user?.stripeRole?.toLowerCase() === productData?.name?.toLowerCase() ? (
                    <Button
                      onClick={() => createPortalLink()}
                      sx={{
                        border: 1,
                        borderColor: theme.palette.brand.brand,
                        color: theme.palette.brand.brand,
                        height: '1.75rem',
                        fontSize: '.75rem',
                        backgroundColor: 'transparent',
                        textTransform: 'capitalize'
                        // '&:hover': {
                        //   backgroundColor: theme.palette.interactions.interactive.hover,
                        //   color: theme.palette.typography.reverse.primary
                        // }
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      className="text-xs"
                      sx={{
                        border: 1,
                        borderColor: theme.palette.brand.brand,
                        color: theme.palette.brand.brand,
                        height: '1.75rem',
                        fontSize: '.75rem',
                        textTransform: 'capitalize',
                        color: theme.palette.brand.brand
                      }}
                    >
                      Upgrade
                    </Button>
                  )}

                  <Button
                    size="small"
                    className="text-xs text-onPrimaryBgSofter capitalize"
                    onClick={() => createPortalLink()}
                  >
                    Details
                  </Button>
                </CardActions>
              </Card>
            </StyledBadge>
          );
          // if (auth.user?.stripeRole === 'premium' && productData.name === 'Premium')
          //   return (
          //     <Card sx={{ maxWidth: 345 }}>
          //       <CardActionArea>
          //         <CardContent>
          //           <Typography
          //             gutterBottom
          //             variant="h5"
          //             component="div"
          //           >
          //             {productData.name}
          //           </Typography>
          //           <Typography
          //             variant="body2"
          //             color="text.secondary"
          //           >
          //             Manage subscription
          //           </Typography>
          //         </CardContent>
          //       </CardActionArea>
          //     </Card>
          //   );
          // return (
          //   <div>
          //     <h1>{productData.name}</h1>
          //     <h2>{productData.description}</h2>
          //     <button
          //       className="bg-black text-white"
          //       onClick={() => createCheckoutSession(auth.user.email, productData.prices.priceId)}
          //     >
          //       Subscribe
          //     </button>
          //   </div>
          // );
        })}
      </div>
    </div>
  );
}
