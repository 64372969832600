import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import YouTube from 'react-youtube';
import { getFirestore, query, getDocs, collection, where, addDoc, getDoc, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from '../../../api/firebase';
import { useAuthContext } from '../../../contexts/AuthContext';

const SupportPage = () => {
  const auth = useSelector((state) => state.auth);
  const [feedback, setFeedback] = useState('');

  // Replace the videoId with the YouTube video ID you want to embed
  const videoId = 'YOUR_YOUTUBE_VIDEO_ID';

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const mailCollectionRef = collection(db, 'mail');

      // Get the user's email (if they are logged in)
      const userEmail = auth.user ? auth.user.email : 'Anonymous User';

      // Create a new document in the "mail" collection with the form data
      await addDoc(mailCollectionRef, {
        to: 'john@fencesketch.com',
        cc: userEmail,
        message: {
          subject: 'New Feedback Submission - Fence Sketch',
          text: feedback
        }
      });

      // Clear the form and show a success message to the user
      setFeedback('');
      alert('Form submitted successfully! Thank you for your feedback.');
    } catch (error) {
      // Handle any errors that might occur during form submission
      console.error('Error submitting the form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };

  return (
    <div className="p-6">
      {/* <div className="mb-6">
        <h1 className="text-2xl font-bold mb-2">Video Guide</h1>
        <YouTube videoId={videoId} />
      </div> */}

      <div className="mb-6">
        <h1 className="text-2xl font-bold mb-2">Get Support</h1>
        <p className="mb-2">Phone: (844) 847-8050</p>
        <p>Email: support@fencesketch.com</p>
      </div>

      <form
        onSubmit={handleFormSubmit}
        className="text-center"
      >
        <h1 className="text-2xl font-bold mb-2">Feedback & Questions</h1>
        <textarea
          value={feedback}
          onChange={(event) => setFeedback(event.target.value)}
          rows="4"
          className="w-full p-2 border border-gray-300 rounded mb-2"
          placeholder="Type your feedback or question here"
        />
        <button
          type="submit"
          className="bg-[#3F3F3F] hover:bg-[#3F3F3F]/50 text-white font-bold py-2 px-4 rounded"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SupportPage;
