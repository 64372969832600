import React, { createContext, useReducer, useContext } from 'react';

export const SketchContext = createContext();

// Initial state
const INITIAL_STATE = {
  contact: null,
  objects: []
};

// Actions
export const SET_CONTACT = 'SET_CONTACT';
export const SET_OBJECTS = 'SET_OBJECTS';
export const DELETE_OBJECT = 'DELETE_OBJECT';
export const ADD_OBJECT = 'ADD_OBJECT';
export const RESET_STATE = 'RESET_STATE';
// Action creators
export function setContact(data) {
  return { type: SET_CONTACT, data: data };
}
export function setObjects(data) {
  return { type: SET_OBJECTS, data: data };
}
export function deleteObject(data) {
  console.log(data);

  return { type: DELETE_OBJECT, data: data };
}
export function addObject(data) {
  return { type: ADD_OBJECT, data: data };
}
export function resetState() {
  return { type: RESET_STATE };
}

// Reducer
export function sketchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CONTACT:
      return { ...state, contact: action.data };
    case SET_OBJECTS:
      //console.log(action.data);
      return { ...state, objects: action.data };
    case DELETE_OBJECT:
      console.log(action.data);

      return { ...state, objects: state.objects.filter((e) => e.key !== action.data) };
    case ADD_OBJECT:
      return { ...state, objects: [...state.objects, action.data] };
    case RESET_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}

function SketchProvider(props) {
  const [sketch, dispatch] = useReducer(sketchReducer, INITIAL_STATE);

  const sketchData = { sketch, dispatch };

  return (
    <SketchContext.Provider
      value={sketchData}
      {...props}
    >
      {props.children}
    </SketchContext.Provider>
  );
}

function useSketchContext() {
  return useContext(SketchContext);
}

export { SketchProvider, useSketchContext };
