import React, { useEffect, useState } from 'react';

import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { db, auth, onAuthStateChanged } from '../api/firebase'; // Path to your firebase.js file
import { doc, getDocs, collection, where, query } from 'firebase/firestore'; // Import Firestore methods
import { useAuthState } from 'react-firebase-hooks/auth';
import { getUser, getUsersOrganization } from '../api/user';
import Loader from './Loader';

const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);
  const userState = useSelector((state) => state.auth);
  const [organizationColor, setOrganizationColor] = useState('237 85 33'); // Default color
  const [organizationColorHex, setOrganizationColorHex] = useState('#ed5521'); // Default color

  useEffect(async () => {
    if (loading && !user) return;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !loading) {
        console.log(user);
        dispatch({ type: 'SET_LOADING', data: true });
        console.log(userState);
        let userRef;
        if (userState.user?.ref) {
          userRef = userState.user.ref;
        } else {
          await wait(3000);
          userRef = await getDocs(query(collection(db, 'users'), where('uid', '==', user.uid))).then((querySnapshot) => {
            console.log(querySnapshot);
            if (querySnapshot.docs.length === 0) {
              dispatch({ type: 'LOGOUT' });
              return navigate('/logout');
            } else {
              return querySnapshot.docs[0].ref;
            }
          });
        }
        console.log(userRef);
        const userData = await getUser(userRef);
        console.log(userData);
        dispatch({
          type: 'SET_USER',
          data: { ...userData, photoURL: user.photoURL, uid: user.uid, email: user.email, displayName: user.displayName, ref: userRef, id: userRef.id }
        });

        const organizationData = await getUsersOrganization(userRef);
        console.log(organizationData);

        if (organizationData.color) {
          const rgbColor = hexToRgb(organizationData.color);
          setOrganizationColor(rgbColor); // Set the organization color
          setOrganizationColorHex(organizationData.color); // Set the organization color
        }

        dispatch({ type: 'SET_ORGANIZATION', data: organizationData });
        dispatch({ type: 'SET_LOADING', data: false });

        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            //console.log(idTokenResult);
            const lastSignIn = idTokenResult.claims.auth_time;
            const oneWeekAgo = Date.now() / 1000 - 7 * 24 * 60 * 60;

            if (lastSignIn < oneWeekAgo) {
              // Force sign out or re-authentication
              dispatch({ type: 'LOGOUT' });
              navigate('/logout');
              //alert('Please sign in again.');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (!loading && !user) {
        // User is signed out
        dispatch({ type: 'LOGOUT' });
        navigate('/logout');
      }
    });

    return () => unsubscribe(); // Unsubscribe from the onAuthStateChanged listener when the component unmounts
  }, [loading]);

  useEffect(() => {
    // Update the CSS variable when organizationColor changes
    const styleElement = document.createElement('style');
    console.log(organizationColor, organizationColorHex);
    styleElement.innerHTML = `:root { --color-primary: ${organizationColor}; --color-primary-hex: ${organizationColorHex};}`;

    document.head.appendChild(styleElement);
  }, [organizationColor, organizationColorHex]);

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

const hexToRgb = (hex) => {
  // Remove the "#" symbol if present
  hex = hex.replace('#', '');

  // Convert the hex value to separate RGB components
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Combine the RGB components without commas
  return `${r} ${g} ${b}`;
};

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
