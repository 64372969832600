// Example authReducer.js

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, LOGIN, SET_USER } from '../actions/authActions';

// Initial state
const initialState = {
  user: null,
  loading: false,
  error: null
};

// Reducer function
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.data
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null
      };
    case 'SET_USER':
      //console.log(action.data);
      return {
        ...state,
        user: action.data
      };
    default:
      return state;
  }
};

export default authReducer;
